import CloseModalButton from '../components/modal/CloseModalButton';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FieldTextArea from '../components/FieldTextArea';
import './CoversContentModal.scss';
import {
    insertCoverPage,
    removeCoverPage,
} from './editor-mods/modules/core/PageManipulation';
import { getBrailleDocument } from './editor-mods/modules/core/EditorUtil';

/**
 * @typedef {object} FormData
 * @property {string} cover
 * @property {string} backCover
 * @property {string} technicalSheet
 */

/**
 * @typedef {object} FormError
 * @property {string | null | undefined} cover
 * @property {string | null | undefined} backCover
 * @property {string | null | undefined} technicalSheet
 */

/**
 * @param show {boolean}
 * @param onClose {function}
 * @param coverElement {HTMLElement}
 * @param editor {EditorCustom}
 * @returns {JSX.Element}
 * @constructor
 */
function CoversContentModal({ show, onClose, coverElement, editor }) {
    /**
     * @type {FormData | null}
     */
    const formDataInitialValue = null;
    const [formData, setFormData] = useState(formDataInitialValue);

    const initialFormError = {};
    const [formError, setFormError] = useState(initialFormError);

    const editing = !getBrailleDocument(editor)?.readOnly;

    useEffect(() => {
        if (!editor) return;

        updateFormData({
            cover: coverElement?.querySelectorAll('[data-type="cover"]')[0]
                ?.innerText,
            backCover: coverElement?.querySelectorAll(
                '[data-type="back-cover"]',
            )[0]?.innerText,
            technicalSheet: coverElement?.querySelectorAll(
                '[data-type="technical-sheet"]',
            )[0]?.innerText,
        });
    }, [show, editor, coverElement]);

    function updateFormData(formData) {
        setFormData((prevState) => {
            return { ...prevState, ...formData };
        });
    }

    /**
     * @param formData {FormData}
     * @return {{hasErrors: boolean, errors: FormError}}
     */
    function hasValidationErrorDocument(formData) {
        let errors = {};
        let hasErrors = false;

        if (!!formData && (!formData.cover || formData.cover.trim() === '')) {
            errors.cover = 'Campo obrigatório.';
            hasErrors = true;
        }

        if (
            !!formData &&
            (!formData.backCover || formData.backCover.trim() === '')
        ) {
            errors.backCover = 'Campo obrigatório.';
            hasErrors = true;
        }

        return { hasErrors, errors };
    }

    function hasValidationError() {
        const { hasErrors, errors } = hasValidationErrorDocument(formData);
        setFormError(errors);
        return hasErrors;
    }

    function save() {
        if (hasValidationError()) return;

        removeCoverPage(editor);
        /**
         * @type {CoversDto}
         */
        const coverDto = {
            ...formData,
        };
        insertCoverPage(editor, coverDto);
        onClose();
    }

    const classList = ['modal', 'default-modal', 'cover-content-modal'];
    if (show) {
        classList.push('show');
    }

    return (
        <div className={classList.join(' ')}>
            <div className={'backdrop'} />
            <div className={'container'}>
                <CloseModalButton onClick={onClose} />
                {/*I18N*/}
                <h2>{'Modelos de página'}</h2>
                <div className={'gd-inner'}>
                    <div className={'gd-col gd-col-7--desktop'}>
                        <form className={'condensed-controls'}>
                            <section className={'modal-form'}>
                                <div className={'gd-inner'}>
                                    <div className={'gd-col gd-col-7--desktop'}>
                                        <div className={'cover-container'}>
                                            <FieldTextArea
                                                // I18N
                                                label={'Capa'}
                                                required={true}
                                                wrap={'off'}
                                                disabled={!editing}
                                                validationError={
                                                    formError.cover
                                                }
                                                value={formData?.cover ?? ''}
                                                rows={20}
                                                onChange={(e) => {
                                                    updateFormData({
                                                        cover: e.target.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={'gd-inner'}>
                                    <div className={'gd-col gd-col-7--desktop'}>
                                        <div className={'cover-container'}>
                                            <FieldTextArea
                                                // I18N
                                                label={'Folha de rosto'}
                                                required={true}
                                                wrap={'off'}
                                                disabled={!editing}
                                                validationError={
                                                    formError.backCover
                                                }
                                                value={
                                                    formData?.backCover ?? ''
                                                }
                                                rows={20}
                                                onChange={(e) => {
                                                    updateFormData({
                                                        backCover:
                                                            e.target.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={'gd-inner'}>
                                    <div className={'gd-col gd-col-7--desktop'}>
                                        <div className={'cover-container'}>
                                            <FieldTextArea
                                                // I18N
                                                label={'Ficha técnica'}
                                                required={true}
                                                wrap={'off'}
                                                disabled={!editing}
                                                validationError={
                                                    formError.technicalSheet
                                                }
                                                value={
                                                    formData?.technicalSheet ??
                                                    ''
                                                }
                                                rows={20}
                                                onChange={(e) => {
                                                    updateFormData({
                                                        technicalSheet:
                                                            e.target.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {editing && (
                                <div
                                    className={'gd-col--align-between controls'}
                                >
                                    <div>
                                        <button
                                            className={'button'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                onClose();
                                            }}
                                        >
                                            {'Cancelar'}
                                        </button>
                                        <button
                                            className={`button primary`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                save();
                                            }}
                                        >
                                            {'Editar'}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

CoversContentModal.propTypes = {
    show: PropTypes.bool,
    coverElement: PropTypes.object,
    editor: PropTypes.object,
    onClose: PropTypes.func.isRequired,
};

export default CoversContentModal;
