/**
 * Remember to update the bash script when changing this file (used in infrastructure build)
 *
 */

const version = 1;
const milestone = 5;
const build = 224;

function getVersion() {
    return `${version}.${milestone}.${build}`;
}

export { version, milestone, build, getVersion };
