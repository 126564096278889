import CloseModalButton from '../components/modal/CloseModalButton';
import { useContext, useEffect, useState } from 'react';
import './PaginationModal.scss';
import PropTypes from 'prop-types';
import FieldInteger from '../components/FieldInteger';
import * as DocumentService from '../services/DocumentService';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import Loading from '../components/Loading';
import { SaveDocumentValidationErrorTxt } from 'plataforma-braille-common';

/**
 * @typedef {object} FormData
 * @property {string | null | undefined} inkPaginationStartPage
 * @property {string | null | undefined} braillePaginationStartPage
 */

/**
 * @typedef {FormData} FormError
 */

/**
 * @param show {boolean}
 * @param documentId {number}
 * @param onClose {function}
 * @param editor {EditorCustom}
 * @returns {JSX.Element}
 * @constructor
 */
function PaginationModal({ show, documentId, onClose, editor }) {
    const [loadingForm, setLoadingForm] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    /**
     * @type {FormData | null}
     */
    const formDataInitialValue = null;
    const [formData, setFormData] = useState(formDataInitialValue);
    /**
     * @type {FormError}
     */
    const initialFormError = {};
    const [formError, setFormError] = useState(initialFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    const { setLoading, backendConnectionError } =
        useContext(EnvironmentContext);

    useEffect(() => {
        if (!editor) return;
        if (validateOnChange) hasValidationError();
    }, [formData]);

    useEffect(() => {
        if (show) {
            fetchDocument().then();
        }
    }, [show]);

    useEffect(() => {
        setLoading(loadingSave || loadingForm, true);
    }, [loadingSave, loadingForm]);

    async function fetchDocument() {
        try {
            setLoadingForm(true);
            let formData = await DocumentService.getDocument(
                documentId,
                null,
                false,
            );

            const modalFields = new Set([
                'inkPaginationStartPage',
                'braillePaginationStartPage',
            ]);

            for (const key of Object.keys(formData)) {
                if (!modalFields.has(key)) {
                    delete formData[key];
                }
            }
            setFormData({
                braillePaginationStartPage:
                    formData.braillePaginationStartPage?.toString() ?? '',
                inkPaginationStartPage:
                    formData.inkPaginationStartPage?.toString() ?? '',
            });
        } catch (e) {
            backendConnectionError('Fail to fetch document.', e);
        } finally {
            setLoadingForm(false);
        }
    }

    /**
     * @param formData {FormData | null}
     */
    function updateFormData(formData) {
        if (!formData) {
            setFormData(null);
        } else {
            setFormData((prevState) => {
                return { ...prevState, ...formData };
            });
        }
    }

    /**
     * @param formData {FormData}
     * @return {{hasErrors: boolean, errors: FormError}}
     */
    function hasValidationErrorDocument(formData) {
        let errors = {};
        let hasErrors = false;

        if (
            !formData?.inkPaginationStartPage ||
            formData?.inkPaginationStartPage?.trim() === ''
        ) {
            errors.inkPaginationStartPage = 'Campo obrigatório.';
            hasErrors = true;
        }

        if (
            !formData?.braillePaginationStartPage ||
            formData?.braillePaginationStartPage.trim() === ''
        ) {
            errors.braillePagination = 'Campo obrigatório.';
            hasErrors = true;
        }

        return { hasErrors, errors };
    }

    // I18N
    const title = 'Paginação';

    function hasValidationError(document = true) {
        const { hasErrors, errors } = hasValidationErrorDocument(formData);
        if (document) setValidateOnChange(true);
        setFormError(errors);
        return hasErrors;
    }

    async function save() {
        if (hasValidationError()) return;

        try {
            setLoadingSave(true);
            await DocumentService.editDocument(documentId, formData);
            onClose();
        } catch (e) {
            backendConnectionError(
                'Fail to edit document.',
                e,
                null,
                title,
                SaveDocumentValidationErrorTxt,
            );
        } finally {
            setLoadingSave(false);
        }
    }

    return (
        <div
            className={`modal default-modal pagination-modal ${show ? 'show' : ''}`}
        >
            <div className={'backdrop'} />
            <div className={'container'}>
                <CloseModalButton onClick={onClose} />
                <h2>{title}</h2>
                <div className={'gd-inner table-form-container'}>
                    <div className={'gd-col gd-col-4--desktop'}>
                        <div className={'form-container'}>
                            {loadingForm ? (
                                <div className={'gd-inner field-group'}>
                                    <div className={'gd-col gd-col-4--desktop'}>
                                        <div className={'loading-container'}>
                                            <Loading />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <form className={'condensed-controls'}>
                                    <section className={'modal-form'}>
                                        <div className={'form-items'}>
                                            <div className={'gd-inner'}>
                                                <div
                                                    className={
                                                        'gd-col gd-col-2--desktop'
                                                    }
                                                >
                                                    <FieldInteger
                                                        // I18N
                                                        label={
                                                            'Numeração inicial - tinta'
                                                        }
                                                        // I18N
                                                        placeholder={
                                                            'Digite aqui'
                                                        }
                                                        required={true}
                                                        validationError={
                                                            formError.inkPaginationStartPage
                                                        }
                                                        value={
                                                            formData?.inkPaginationStartPage ??
                                                            ''
                                                        }
                                                        onChange={(e) =>
                                                            updateFormData({
                                                                inkPaginationStartPage:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        'gd-col gd-col-2--desktop'
                                                    }
                                                >
                                                    <FieldInteger
                                                        // I18N
                                                        label={
                                                            'Numeração inicial - braille'
                                                        }
                                                        // I18N
                                                        placeholder={
                                                            'Digite aqui'
                                                        }
                                                        required={true}
                                                        validationError={
                                                            formError.braillePaginationStartPage
                                                        }
                                                        value={
                                                            formData?.braillePaginationStartPage ??
                                                            ''
                                                        }
                                                        onChange={(e) =>
                                                            updateFormData({
                                                                braillePaginationStartPage:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </form>
                            )}
                            <div className={'controls'}>
                                <button
                                    className={'button'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onClose();
                                    }}
                                >
                                    {/*I18N*/}
                                    {'Cancelar'}
                                </button>
                                <button
                                    className={`button primary ${loadingSave ? 'loading' : ''}`}
                                    onClick={() => {
                                        save().then();
                                    }}
                                >
                                    {/*I18N*/}
                                    {'Salvar'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

PaginationModal.propTypes = {
    documentId: PropTypes.string,
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

export default PaginationModal;
