import { RevisionGravityEnum } from '../RevisionGravityEnum';
import { RevisionErrorEnum } from '../RevisionErrorEnum';

/**
 * @implements {RevisionCheck}
 */
export class ImageNeedsReview {
    /**
     * @param {number} pageNumber
     * @param {HTMLElement | Node} page
     * @returns {Promise<import('RevisionRecord').RevisionRecord[]>}
     */
    async check(pageNumber, page) {
        const elements = [
            ...page.querySelectorAll(
                'editor-element[type="image"][data-needs-review="true"]',
            ),
        ];
        /**
         *
         * @type {import('RevisionRecord').RevisionRecord[]}
         */
        const records = [];
        for (const element of elements) {
            if (element.getAttribute('data-score-revised') !== 'true') {
                records.push({
                    revisionGravity: RevisionGravityEnum.WARNING,
                    inspectionError: RevisionErrorEnum.IMAGE_NEEDS_REVIEW,
                    page: pageNumber,
                    editorElementId: element.getAttribute('id'),
                });
            }
        }
        return records;
    }

    async releaseWorker() {}
}
