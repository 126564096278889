import {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import * as DocumentService from '../services/DocumentService';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import FieldText from '../components/FieldText';
import CloseModalButton from '../components/modal/CloseModalButton';
import Loading from '../components/Loading';
import { SaveDocumentValidationErrorTxt } from 'plataforma-braille-common';

/**
 * @typedef {object} RenameDocumentModalFunctions
 * @param showRenameDocumentModal {function(number, function(DocumentDto))}
 */

/**
 * @type {React.ForwardRefExoticComponent<>}
 */
const RenameDocumentModal = forwardRef((_, ref) => {
    const [show, setShow] = useState(false);
    /**
     * @type {number | null}
     */
    const initialDocumentId = null;
    const [documentId, setDocumentId] = useState(initialDocumentId);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(null);
    const [loadingName, setLoadingName] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const { setLoading, setInfoModal, backendConnectionError } =
        useContext(EnvironmentContext);
    const currentShowRef = useRef(false);
    const inputNameRef = useRef(null);
    /**
     * @type {React.MutableRefObject<function(DocumentDto|undefined):void | null>}
     */
    const onRenamedCallbackRef = useRef(null);

    /**
     * @param documentId {number}
     * @param onRenamedCallback {function(DocumentDto):void}
     */
    function showRenameDocumentModal(documentId, onRenamedCallback) {
        setDocumentId(documentId);
        onRenamedCallbackRef.current = onRenamedCallback;
        setShow(true);
    }

    useImperativeHandle(ref, () => ({ showRenameDocumentModal }));

    async function fetchDocument() {
        try {
            setLoadingName(true);
            const { name } = await DocumentService.getDocument(
                documentId,
                null,
                false,
            );
            setName(name);
        } catch (e) {
            setShow(false);
            backendConnectionError('Fail to fetch document.', e);
        } finally {
            setLoadingName(false);
        }
    }

    useEffect(() => {
        if (currentShowRef.current === show) return;
        currentShowRef.current = show;

        if (show) {
            // noinspection JSIgnoredPromiseFromCall
            fetchDocument();
            setTimeout(() => {
                inputNameRef.current?.focus();
            }, 200); // css transition time
        } else {
            onRenamedCallbackRef.current = null;
        }
    }, [show]);

    useEffect(() => {
        setLoading(loadingSave || loadingName, true);
    }, [loadingSave, loadingName]);

    async function saveDocument() {
        if (hasValidationError()) return;

        try {
            setLoadingSave(true);
            const response = await DocumentService.editDocument(documentId, {
                name,
            });
            if (response.validationError) {
                // noinspection JSUnresolvedReference
                setInfoModal({
                    // I18N
                    title: 'Renomear documento',
                    message: SaveDocumentValidationErrorTxt(
                        response.validationError,
                    ),
                    show: true,
                });
            } else {
                setShow(false);
                if (onRenamedCallbackRef.current) {
                    onRenamedCallbackRef.current(response.entity);
                }
            }
        } catch (e) {
            backendConnectionError('Fail to edit document.', e);
        } finally {
            setLoadingSave(false);
        }
    }

    function hasValidationError() {
        let hasErrors = false;

        if (!name || name.trim() === '') {
            // I18N
            setNameError('Campo obrigatório.');
            hasErrors = true;
        } else {
            setNameError(null);
        }

        return hasErrors;
    }

    return (
        <div className={`modal default-modal ${show ? 'show' : ''}`}>
            <div className={'backdrop'} />
            <div className={'container'} style={{ minWidth: '60%' }}>
                <CloseModalButton onClick={() => setShow(false)} />
                <h2>
                    {/*I18N*/}
                    {'Renomear documento'}
                </h2>
                <div className={'body'}>
                    {loadingName ? (
                        <div className={'loading-container'}>
                            <Loading />
                        </div>
                    ) : (
                        <FieldText
                            ref={inputNameRef}
                            // I18N
                            label={'Novo nome'}
                            // I18N
                            placeholder={'Nome do documento'}
                            validationError={nameError}
                            required={true}
                            maxLength={255}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    )}
                    <div className={'controls'}>
                        <button
                            className={'button'}
                            onClick={() => setShow(false)}
                        >
                            {/*I18N*/}
                            {'Cancelar'}
                        </button>
                        <button
                            className={`button primary ${loadingSave ? 'loading' : ''}`}
                            onClick={() => saveDocument()}
                        >
                            {/*I18N*/}
                            {'Salvar'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});

RenameDocumentModal.displayName = 'RenameDocumentModal';

export default RenameDocumentModal;
