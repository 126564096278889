// import './SummaryModal.scss';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import CloseModalButton from '../../../../components/modal/CloseModalButton';
import { SummaryModalRecord } from './SummaryModalRecord';
import { goToPage } from '../core/PageManipulation';

/**
 * @typedef {object} SummaryModalParams
 */

/**
 * @typedef {object} SummaryModalFunctions
 * @property {function(editor: EditorCustom, records: SummaryRecord[])} showSummaryModal
 */

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<SummaryModalParams>>}
 */
export const SummaryModal = forwardRef((_, ref) => {
    const [show, setShow] = useState(false);
    /**
     * @type {React.MutableRefObject<EditorCustom | null>}
     */
    const editorRef = useRef(null);
    /**
     * @type {SummaryRecord[]}
     */
    const recordsInitialValue = [];
    const [records, setRecords] = useState(recordsInitialValue);

    useImperativeHandle(ref, () => ({ showSummaryModal, closeSummaryModal }));

    const classList = ['modal', 'default-modal'];
    if (show) {
        classList.push('show');
    }

    /**
     * @param editor {EditorCustom}
     * @param records {SummaryRecord[]}
     */
    function showSummaryModal(editor, records) {
        editorRef.current = editor;
        setRecords(records);
        setShow(true);
    }

    function closeSummaryModal() {
        setShow(false);
    }

    function summaryModalRecords() {
        return records.map((record, idx) => (
            <SummaryModalRecord
                key={idx}
                record={record}
                onClick={(record) => {
                    const editor = editorRef.current;
                    goToPage(editor, record.nextPage);
                }}
            />
        ));
    }

    return (
        <div className={classList.join(' ')}>
            <div className={'backdrop'} />
            <div className={'container'}>
                <CloseModalButton onClick={closeSummaryModal} />
                {/*I18N*/}
                <h2>{'Sumário'}</h2>

                {records.length ? (
                    <div className={'record-container'}>
                        {summaryModalRecords()}
                    </div>
                ) : (
                    <div className={'message'}>
                        {/*I18N*/}
                        {'Nenhum item de sumário encontrado no documento.'}
                    </div>
                )}

                <div className={'controls'}>
                    <a className={'button primary'} onClick={closeSummaryModal}>
                        {/*I18N*/}
                        {'Fechar'}
                    </a>
                </div>
            </div>
        </div>
    );
});

SummaryModal.propTypes = {};

SummaryModal.displayName = 'SummaryModal';
