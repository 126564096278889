import {
    getBrailleDocument,
    getClosestEditorElement,
    getClosestElementRepresentationGenericSpace,
    getClosestElementRepresentationLineBreak,
    getClosestPage,
    getSelectedNodes,
    isEditorElement,
    preventScroll,
} from './EditorUtil';
import { ZERO_WIDTH_NB_CHAR, ZERO_WIDTH_SPACE_CHAR } from '../KeyboardModule';
import { getCaretPosition, scanCaretPath } from './CaretPath';
import {
    getEditorElementByType,
    getRegisteredEditorElements,
} from './editor-element/Instances';
import {
    backPropagateBreaksToElement,
    removeParagraphBreaks,
} from '../../../../conversion/braille/HtmlToBraille';
import { setCustomParagraphBreaks } from './BrailleView';
import {
    getCurrentPage,
    getNextPage,
    getPreviousPage,
} from './PageManipulation';

/**
 * @typedef {object} EditorElement
 * @property {function(): string} getEditorElementType
 * @property {function(node: Node): boolean} isNodeInsideElement
 * @property {function(): string[]} getInnerContextContainerCssClass - Returns an array with the classes of containers that can be edited (contentEditable = true). The array is used for caret position handling.
 * @property {function(): boolean} worksNotConvertedToBraille - Allows the execution of the "checkAndRepairElement" function when the conversion to braille has not yet occurred (based on the document's status).
 * @property {function(): boolean} worksConvertedToBraille - Allows the execution of the "checkAndRepairElement" function when the conversion to braille has occurred (based on the document's status).
 * @property {function(): boolean} isBlockingElement
 * @property {(function(EditorCustom): HTMLElement) | (function(): HTMLElement)} createEditorElement
 * @property {(function(EditorCustom): boolean) | (function(editor: EditorCustom, data: object): boolean)} insertElementAtCursor
 * @property {function(HTMLElement, BrailleFacilConversionFlag[], EditorElements, BrailleDocument): string} convertToBraille
 * @property {function(container: HTMLElement): HTMLElement[]} getElementsInContainer
 * @property {function(element: HTMLElement)} checkAndRepairElement
 * @property {function(HTMLElement): string[]} getContextMenu
 * @property {undefined | function(EditorCustom, EditorElements | undefined)} initialize
 * @property {undefined | function} destroy
 * @property {undefined | function(EditorCustom, HTMLElement)} prepareToBraille
 * @property {undefined | function(EditorCustom, HTMLElement)} prepareToNotBraille
 * @property {undefined | (function(): boolean)} supportExcessLinesBetweenPages
 * @property {undefined | (function(EditorCustom, element: HTMLElement, nextPageContainer: HTMLElement, lineCount: number, atEnd: boolean): HTMLElement)} getExcessElement
 * @property {undefined | (function(HTMLElement):boolean)} isEmpty
 * @property {undefined | (function():boolean)} isFixedToTop
 * @property {undefined | (function():boolean)} isFixedToBottom
 * @property {undefined | (function(HTMLElement):HTMLElement[])} getInnerContextContainers
 */

/**
 * @typedef {object} EditorElementRemovedEvent
 * @property {HTMLElement} page
 * @property {HTMLElement} element
 * @property {function()} preventRemove
 */

/**
 * @param editorElement {EditorElement}
 * @param first {boolean | undefined} If undefined, will check both positions
 */
export function isEditorElementFixed(editorElement, first = undefined) {
    if (first || first == null) {
        if (editorElement?.isFixedToTop && editorElement?.isFixedToTop()) {
            return true;
        }
    }
    if (first === false || first == null) {
        if (
            editorElement?.isFixedToBottom &&
            editorElement?.isFixedToBottom()
        ) {
            return true;
        }
    }
    return false;
}

/**
 * @param element {HTMLElement | Node}
 */
export function checkAndRepairContinuation(element) {
    const currentPage = getClosestPage(element);
    const nextPage = getNextPage(currentPage);
    const previousPage = getPreviousPage(element);
    if (isElementContinuation(element)) {
        if (
            !previousPage?.querySelector(
                `[id="${element.getAttribute('data-continuation-element')}"]`,
            )
        ) {
            element.removeAttribute('data-continuation');
            element.removeAttribute('data-continuation-element');
        }
    }
    if (hasElementContinuation(element)) {
        if (
            !nextPage?.querySelector(
                `[data-continuation-element="${element.getAttribute('id')}"]`,
            )
        ) {
            element.removeAttribute('data-has-continuation');
        }
    }
}

/**
 * @param element {HTMLElement}
 * @return {HTMLElement[] | null}
 */
export function getContinuationElementParts(element) {
    let head = element;
    const pagesContainer = getClosestPage(element).parentElement;
    while (isElementContinuation(head)) {
        const idSibling = element.getAttribute('data-continuation-element');
        head = pagesContainer.querySelector(`[id="${idSibling}"]`);
    }
    if (!head) {
        return null;
    }
    const elements = [];
    let walk = head;
    while (walk) {
        elements.push(walk);
        const idElement = walk.getAttribute('id');
        walk = pagesContainer.querySelector(
            `[data-continuation-element="${idElement}"]`,
        );
    }
    return elements;
}

/**
 * @param elementA {HTMLElement}
 * @param elementB {HTMLElement | undefined}
 * @return {boolean}
 */
export function isElementContinuation(elementA, elementB = null) {
    if (elementB == null) {
        return (
            elementA?.getAttribute &&
            elementA.getAttribute('data-continuation') === 'true'
        );
    }

    return (
        elementB?.getAttribute &&
        elementB.getAttribute('data-continuation') === 'true' &&
        elementA.getAttribute('id') ===
            elementB.getAttribute('data-continuation-element')
    );
}

/**
 * @param element {HTMLElement}
 * @returns {boolean}
 */
export function hasElementContinuation(element) {
    return element.getAttribute('data-has-continuation') === 'true';
}

// /**
//  * @param element {HTMLElement}
//  * @return {HTMLElement | null}
//  */
// export function getPreviousElementOfContinuation(element) {
//     const page = getClosestPage(element);
//     const previousPage = getPreviousPage(page);
//     if (!previousPage) return null;
//     return previousPage.querySelector(
//         `[id="${element.getAttribute('data-continuation-element')}"]`,
//     );
// }
//
// /**
//  * @param element {HTMLElement}
//  * @return {HTMLElement | null}
//  */
// export function getNextElementOfContinuation(element) {
//     const page = getClosestPage(element);
//     const nextPage = getNextPage(page);
//     if (!nextPage) return null;
//     return nextPage.querySelector(
//         `[data-continuation-element="${element.getAttribute('id')}"]`,
//     );
// }

/**
 * @param element {HTMLElement | Node}
 * @param checkInside {boolean | undefined}
 * @return {boolean}
 */
export function isInnerContextElement(element, checkInside = false) {
    if (checkInside) {
        element = getClosestEditorElement(element);
        if (!element) {
            return false;
        }
    } else {
        if (!isEditorElement(element)) {
            return false;
        }
    }
    return !!getEditorElement(element)?.getInnerContextContainerCssClass()
        .length;
}

/**
 * @param element {HTMLElement}
 * @returns {EditorElement | null}
 */
export function getEditorElement(element) {
    const closestEditorElement = getClosestEditorElement(element);
    if (!closestEditorElement) return null;
    return getEditorElementByType(closestEditorElement.getAttribute('type'));
}

export function isInlineEditorElement(element) {
    const editorElement = getEditorElement(element);
    return editorElement && !editorElement.isBlockingElement();
}

/**
 * @param editor {EditorCustom}
 * @param element {HTMLElement | Node}
 * @returns {boolean}
 */
export function elementCanBeInsertedAtSelection(editor, element) {
    if (!getClosestPage(editor.selection.getNode())) {
        editor.notificationManager.open({
            // I18N
            text: 'Não é possível inserir elementos fora da página',
            type: 'warning',
            timeout: 5000,
        });
        return false;
    } else if (isEditorElement(element)) {
        if (
            checkAncestorsElement(editor.selection.getNode(), (el) =>
                isEditorElement(el),
            )
        ) {
            let representation = getClosestElementRepresentationLineBreak(
                editor.selection.getNode(),
            );

            // exception when showing non-printable chars
            if (representation) {
                const textNode = document.createTextNode(ZERO_WIDTH_NB_CHAR);
                representation.parentNode.insertBefore(
                    textNode,
                    representation,
                );
                editor.selection.setCursorLocation(textNode, 0);
                return true;
            }
            representation = getClosestElementRepresentationGenericSpace(
                editor.selection.getNode(),
            );
            if (representation) {
                /**
                 * @type {Node}
                 */
                const textNode = document.createTextNode(ZERO_WIDTH_NB_CHAR);
                representation.after(textNode);
                editor.selection.setCursorLocation(textNode, 0);
                return true;
            }

            editor.notificationManager.open({
                // I18N
                text: 'Não é possível inserir elementos em cascata',
                type: 'warning',
                timeout: 5000,
            });
            return false;
        }
    }
    return true;
}

/**
 * @param node { Node }
 * @param testFn { (element: HTMLElement | Node) => boolean }
 * @returns {boolean}
 */
export function checkAncestorsElement(node, testFn) {
    if (!node) return false;
    if (testFn(node)) return true;
    return checkAncestorsElement(node.parentNode, testFn);
}

/**
 * @returns {HTMLElement}
 */
export function createInvisibleParagraphBreak() {
    /**
     * @type {HTMLElement}
     */
    const br = document.createElement('br');
    br.style.display = 'none';
    return br;
}

/**
 * @param element {HTMLElement | Node}
 */
export function fixInvisibleParagraphBreak(element) {
    /**
     * @type {HTMLElement | any}
     */
    let br = element.nextSibling;
    if (br?.tagName === 'BR') {
        br.style.display = 'none';
    } else {
        br = document.createElement('br');
        br.style.display = 'none';
        element.after(br);
    }
}

/**
 * @param element {HTMLElement | Node}
 */
export function removeInvisibleParagraphBreak(element) {
    /**
     * @type {HTMLElement}
     */
    let br = element.nextElementSibling;
    if (br?.tagName === 'BR' && br.style.display === 'none') {
        br.remove();
    }
}

/**
 * @param editor {EditorCustom}
 * @param element {HTMLElement}
 */
export function removeEditorElement(editor, element) {
    /**
     * @type {HTMLElement}
     */
    const page = getClosestPage(element);
    if (isEditorElement(element)) {
        let preventRemove = false;
        /**
         * @type {EditorElementRemovedEvent}
         */
        const editorElementRemovedEvent = {
            page,
            element,
            preventRemove: () => {
                preventRemove = true;
            },
        };

        const type = element.getAttribute('type');
        editor.fire('editorElementBeforeRemove', editorElementRemovedEvent);
        if (type) {
            editor.fire(
                `editorElementBeforeRemove@${type}`,
                editorElementRemovedEvent,
            );
        }

        if (preventRemove) return;

        editor.undoManager.transact(() => {
            element.remove();
            setTimeout(() => {
                preventScroll(editor);
            }, 0);
        });

        editor.fire('editorElementRemoved', editorElementRemovedEvent);
        if (type) {
            editor.fire(
                `editorElementRemoved@${type}`,
                editorElementRemovedEvent,
            );
        }
    }

    /**
     * @type {CaretPosition}
     */
    const caretPosition = {
        path: [],
        nodePath: [],
        page,
        contextElement: page,
    };
    /**
     * @type {PageDataChangedEvent}
     */
    const pageDataChangedEvent = { caretPosition };
    editor.fire('pageDataChanged', pageDataChangedEvent);
}

/**
 * @param container {HTMLElement}
 * @param breaks {ParagraphBreak[]}
 * @param insideElements {boolean | undefined}
 */
export function breakParagraphsEditorElementContainer(
    container,
    breaks,
    insideElements = false,
) {
    removeParagraphBreaks(container, true);
    backPropagateBreaksToElement(container, breaks, insideElements);
    setCustomParagraphBreaks(container);
}

/**
 * @param element {HTMLElement}
 */
export function clearEditorElementLinesCount(element) {
    element.removeAttribute('data-lines-count');
}

/**
 * @param element {HTMLElement}
 * @param lineCount {number}
 */
export function setEditorElementLinesCount(element, lineCount) {
    element.setAttribute('data-lines-count', `${lineCount}`);
}

/**
 * @param brailleData {string | string[]}
 * @param mark {MARK_CHAR}
 * @returns {string | string[]}
 */
export function markLines(brailleData, mark) {
    let isArray = false;
    let array;
    if (Array.isArray(brailleData)) {
        isArray = true;
        array = brailleData;
    } else {
        array = brailleData.split('\r\n');
    }
    array = array.map((line) => mark + line + mark);
    if (isArray) return array;
    return array.join('\r\n');
}

/**
 * @param element {HTMLElement}
 * @return {number | null}
 */
export function getEditorElementLinesCount(element) {
    if (!element || !element.getAttribute) return null;
    let dataLineCount = element.getAttribute('data-lines-count');
    if (dataLineCount) {
        const numberValue = parseInt(dataLineCount);
        if (isNaN(numberValue)) return null;
        return numberValue;
    }
    return null;
}

/**
 * @param element {HTMLElement}
 */
export function surroundElementWithZeroWidthSpace(element) {
    /**
     * @type {Node}
     */
    let zeroWidthSpace;
    if (!element.previousSibling?.nodeValue?.endsWith(ZERO_WIDTH_SPACE_CHAR)) {
        zeroWidthSpace = document.createTextNode(ZERO_WIDTH_SPACE_CHAR);
        element.before(zeroWidthSpace);
    }
    if (!element.nextSibling?.nodeValue?.startsWith(ZERO_WIDTH_SPACE_CHAR)) {
        zeroWidthSpace = document.createTextNode(ZERO_WIDTH_SPACE_CHAR);
        element.after(zeroWidthSpace);
    }
}

export class EditorElements {
    /**
     * @param editor {EditorCustom | null}
     */
    constructor(editor) {
        this.editor = editor;
        this.initialize();
    }

    initialize() {
        if (!this.editor) {
            return;
        }
        for (const editorElement of Object.values(
            getRegisteredEditorElements(),
        )) {
            if (editorElement.initialize) {
                editorElement.initialize(this.editor, this);
            }
        }
        const self = this;

        this.editor.on('editorElementFocused', (e) => {
            /**
             * @type {HTMLElement}
             */
            const focusElement = e?.element;

            const editorElement = getEditorElement(focusElement);
            if (editorElement) {
                editorElement.checkAndRepairElement(focusElement);
                self.editor.fire(
                    `editorElementFocused@${editorElement.getEditorElementType()}`,
                    e,
                );
            }
        });

        this.editor.on('editorElementBlurred', (e) => {
            /**
             * @type {HTMLElement}
             */
            const blurElement = e?.element;

            let editorElement = getEditorElement(blurElement);
            if (editorElement) {
                self.editor.fire(
                    `editorElementBlurred@${editorElement.getEditorElementType()}`,
                    e,
                );
            }
        });

        /**
         * @type {HTMLElement[] | null}
         */
        let beforeInputElements;
        this.editor.on('beforeInput', (e) => {
            beforeInputElements = null;
            const deleteBackward = e?.inputType === 'deleteContentBackward';
            const deleteForward = e?.inputType === 'deleteContentForward';
            if (deleteBackward || deleteForward) {
                const selectedNodes = getSelectedNodes(self.editor);
                if (!selectedNodes.length) {
                    const node = self.editor.selection.getNode();

                    if (!isEditorElement(node)) {
                        // remove editor elements on borders
                        const caretPosition = getCaretPosition(self.editor);
                        const idx = caretPosition.path.length - 1;
                        /**
                         * @type {null | HTMLElement}
                         */
                        let borderEditorElement = null;
                        if (deleteBackward) {
                            if (isEditorElement(caretPosition[idx])) {
                                borderEditorElement = caretPosition[idx];
                            } else if (
                                caretPosition.path[idx] === '\n' &&
                                isEditorElement(caretPosition.path[idx - 1])
                            ) {
                                borderEditorElement =
                                    caretPosition.path[idx - 1];
                            }
                        } else {
                            const caretPosition = scanCaretPath(
                                getCurrentPage(self.editor),
                            );
                            if (isEditorElement(caretPosition[idx + 1])) {
                                borderEditorElement = caretPosition[idx + 1];
                            } else if (
                                caretPosition[idx + 1] === '\n' &&
                                isEditorElement(caretPosition[idx + 2])
                            ) {
                                borderEditorElement = caretPosition[idx + 2];
                            }
                        }

                        if (borderEditorElement) {
                            e.preventDefault();
                            removeEditorElement(
                                self.editor,
                                borderEditorElement,
                            );
                        }
                    } else {
                        e.preventDefault();
                        removeEditorElement(self.editor, node);
                    }
                } else {
                    beforeInputElements = [];
                    for (const node of selectedNodes) {
                        if (!isEditorElement(node)) continue;
                        /**
                         * @type {HTMLElement}
                         */
                        const element = node;
                        beforeInputElements.push(element);
                        const page = getClosestPage(element);
                        let preventRemove = false;

                        /**
                         * @type {EditorElementRemovedEvent}
                         */
                        const editorElementRemovedEvent = {
                            page,
                            element: element,
                            preventDefault: () => {
                                preventRemove = true;
                            },
                        };

                        const type = element.getAttribute('type');
                        self.editor.fire(
                            'editorElementBeforeRemove',
                            editorElementRemovedEvent,
                        );
                        if (type) {
                            self.editor.fire(
                                `editorElementBeforeRemove@${type}`,
                                editorElementRemovedEvent,
                            );
                        }

                        if (preventRemove) {
                            beforeInputElements = null;
                            e.preventDefault();
                            return;
                        }
                    }
                }
            }
        });

        this.editor.on('input', () => {
            if (!beforeInputElements) return;
            for (const element of beforeInputElements) {
                const type = element.getAttribute('type');
                const page = getClosestPage(element);
                /**
                 * @type {EditorElementRemovedEvent}
                 */
                const editorElementRemovedEvent = {
                    page,
                    element: element,
                    preventRemove: () => {},
                };

                self.editor.fire(
                    'editorElementRemoved',
                    editorElementRemovedEvent,
                );
                if (type) {
                    self.editor.fire(`editorElementRemoved@${type}`, {
                        editorElementRemovedEvent,
                    });
                }
            }
        });
    }

    /**
     * @param editorElementId {string}
     * @returns {EditorElement}
     */
    getEditorElementInstance(editorElementId) {
        const editorElement = getRegisteredEditorElements()[editorElementId];
        if (!editorElement) {
            throw new Error(
                `Editor element does not exist: ${editorElementId}`,
            );
        }
        return editorElement;
    }

    /**
     * @param container {HTMLElement}
     */
    checkAndRepairElements(container) {
        const brailleDocument = getBrailleDocument(this.editor);
        for (const editorElement of Object.values(
            getRegisteredEditorElements(),
        )) {
            if (
                brailleDocument.convertedToBraille &&
                editorElement.prepareToBraille
            ) {
                editorElement.prepareToBraille(this.editor, container);
            } else if (
                !brailleDocument.convertedToBraille &&
                editorElement.prepareToNotBraille
            ) {
                editorElement.prepareToNotBraille(this.editor, container);
            }

            if (
                (brailleDocument.convertedToBraille &&
                    editorElement.worksConvertedToBraille()) ||
                (!brailleDocument.convertedToBraille &&
                    editorElement.worksNotConvertedToBraille())
            ) {
                const elements =
                    editorElement.getElementsInContainer(container);
                for (const element of elements) {
                    editorElement.checkAndRepairElement(element);
                }
            }
        }
    }

    /**
     * @param element {HTMLElement}
     * @return {string[] | string};
     */
    getContextMenu(element) {
        return getEditorElement(element)?.getContextMenu(element);
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param brailleDocument {BrailleDocument | null}
     * @return {string | null}
     */
    convertToBraille(element, flags, brailleDocument) {
        return (
            getEditorElement(element)?.convertToBraille(
                element,
                flags,
                this,
                brailleDocument || getBrailleDocument(this.editor),
            ) ?? null
        );
    }

    /**
     * @param editorElementId {string}
     * @param data {object | undefined}
     * @returns {boolean}
     */
    insertElementAtCursor(editorElementId, data = null) {
        const editorElement = this.getEditorElementInstance(editorElementId);
        if (!editorElement) {
            return false;
        }
        if (editorElement.insertElementAtCursor(this.editor, data)) {
            /**
             * @type {PageDataChangedEvent}
             */
            const pageDataChangedEvent = {
                caretPosition: getCaretPosition(this.editor),
            };
            this.editor.fire('pageDataChanged', pageDataChangedEvent);
            return true;
        }
        return false;
    }

    /**
     * @param container {HTMLElement}
     */
    removeLostParagraphBreak(container) {
        /**
         * @type {HTMLBRElement[]}
         */
        const brs = [...container.querySelectorAll('br')];
        for (let element of brs) {
            if (element.style?.display !== 'none') continue;
            const partner = element.previousElementSibling;
            const editorElement = getEditorElement(partner);
            if (!partner || !editorElement?.isBlockingElement()) {
                element.remove();
            }
        }
    }
}
