import {
    generateId,
    getClosestPage,
    isEditorElement,
    isInside,
} from '../EditorUtil';
import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import {
    breakParagraphsEditorElementContainer,
    clearEditorElementLinesCount,
    createInvisibleParagraphBreak,
    fixInvisibleParagraphBreak,
    markLines,
    removeInvisibleParagraphBreak,
    setEditorElementLinesCount,
} from '../EditorElements';
import { BrailleFacilConversionFlag } from '../../../../../conversion/txt/BrailleFacilConversionFlag';
import {
    backPropagateBreaksToText,
    convertElementToBraille,
} from '../../../../../conversion/braille/HtmlToBraille';
import { MARK_CHAR } from '../../../../../conversion/braille/CharMap';
import { getBrailleParagraphs } from '../BrailleView';
import { extractRecursively } from '../../../../../conversion/txt/HtmlToBrailleFacil';
import {
    EDITOR_ELEMENT_FOOTNOTE_MARK,
    getFootnoteElementFromPage,
} from './EditorElementFootnote';

/**
 * @param node {Node | HTMLElement}
 * @returns {boolean}
 */
function isEditorElementFootnoteItem(node) {
    return node?.getAttribute && node.getAttribute('type') === 'footnote-item';
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
export function isInsideEditorElementFootnoteItem(node) {
    return isInside(
        node,
        (node) => isEditorElement(node) && isEditorElementFootnoteItem(node),
    );
}

/**
 * @implements {EditorElement}
 */
export class EditorElementFootnoteItem {
    /**
     * @type {null | EditorCustom}
     */
    editor = null;

    /**
     * @type {null | EditorElements}
     */
    editorElements = null;

    /**
     * @param editor {EditorCustom}
     * @param editorElements {EditorElements}
     */
    initialize(editor, editorElements) {
        this.editor = editor;
        this.editorElements = editorElements;

        const self = this;
        this.editor.on(
            `editorElementBlurred@${this.getEditorElementType()}`,
            (e) => {
                const element = e?.element;
                const page = getClosestPage(element);
                if (!page) return;
                const footnoteElement = getFootnoteElementFromPage(page);
                self.reorderElements(footnoteElement, page);
            },
        );

        /**
         * @param e {EditorElementRemovedEvent}
         */
        const editorElementBeforeRemove = (e) => {
            e.preventRemove();
        };
        editor.on(
            `editorElementBeforeRemove@${this.getEditorElementType()}`,
            editorElementBeforeRemove,
        );
    }

    /**
     * @returns {EditorElementFootnoteMark | null}
     */
    getEditorElementFootnoteMark() {
        return (
            this.editorElements.getEditorElementInstance(
                EDITOR_ELEMENT_FOOTNOTE_MARK,
            ) ?? null
        );
    }

    /**
     * @returns {boolean}
     */
    isFixedToBottom() {
        return true;
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'footnote-item';
    }

    /**
     * @param node {Node}
     * @returns {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementFootnoteItem(node);
    }

    /**
     * @returns {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.mark', '.text'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    isBlockingElement() {
        return true;
    }

    /**
     * @param editor {EditorCustom | undefined}
     * @param footnote {string | undefined}
     * @return {HTMLElement}
     */
    createEditorElement(editor = undefined, footnote = undefined) {
        const editorElement = document.createElement('editor-element');
        const idPrefix = 'editor-element-footnote-item';
        const elementId = generateId(editor, idPrefix);

        editorElement.setAttribute('type', 'footnote-item');
        editorElement.setAttribute('id', elementId);
        editorElement.setAttribute('contentEditable', 'false');

        const markContainer = document.createElement('div');
        markContainer.className = 'mark';
        markContainer.setAttribute('contentEditable', 'false');
        editorElement.appendChild(markContainer);

        const textContainer = document.createElement('div');
        textContainer.className = 'text';
        textContainer.setAttribute('contentEditable', 'true');
        textContainer.innerHTML = footnote ?? ZERO_WIDTH_NB_CHAR;
        editorElement.appendChild(textContainer);

        return editorElement;
    }

    /**
     * @param footnoteElement {HTMLElement}
     * @param mark {string}
     * @param footnote {string | undefined}
     * @param foreignLetterAccent {string | undefined}
     * @return {HTMLElement}
     */
    addFootnoteItem(
        footnoteElement,
        mark,
        footnote = '',
        foreignLetterAccent = undefined,
    ) {
        const element = this.createEditorElement(this.editor);
        const markContainer = element.querySelector('.mark');
        markContainer.innerText = mark + ' ';

        const textContainer = element.querySelector('.text');
        textContainer.innerText = footnote ?? ZERO_WIDTH_NB_CHAR;

        const footnoteElementTextContainer =
            footnoteElement.querySelector('.text');
        footnoteElementTextContainer.appendChild(element);

        if (foreignLetterAccent) {
            element.setAttribute('data-foreign-accent', foreignLetterAccent);
        }

        element.after(createInvisibleParagraphBreak());
        return element;
    }

    getElementByForeignLetterAccent(page, foreignLetterAccent) {
        return page.querySelector(
            `[type="${this.getEditorElementType()}"][data-foreign-accent="${foreignLetterAccent.replaceAll('"', '"')}"]`,
        );
    }

    /**
     * @return {boolean}
     */
    insertElementAtCursor() {
        throw new Error('This element cannot be inserted at cursor.');
    }

    /**
     * @param container {HTMLElement}
     * @returns {HTMLElement[]}
     */
    getElementsInContainer(container) {
        return [
            ...container.querySelectorAll(
                'editor-element[type="footnote-item"]',
            ),
        ];
    }

    /**
     * @param footnoteElement {HTMLElement}
     * @param page {HTMLElement}
     */
    reorderElements(footnoteElement, page) {
        for (const [i, element] of this.getElementsInContainer(
            page,
        ).entries()) {
            const id = element.getAttribute('id');
            const markContainer = element.querySelector('.mark');
            markContainer.innerText = '(**' + (!i ? '' : i + 1) + ') ';

            const elementsMarks =
                this.getEditorElementFootnoteMark().getElementsByItemId(
                    id,
                    page,
                );

            for (const elementMark of elementsMarks) {
                const elementFootnoteMarkTextContainer =
                    elementMark.querySelector('.text');
                if (!elementFootnoteMarkTextContainer) continue;
                elementFootnoteMarkTextContainer.innerText =
                    markContainer.innerText;
            }
        }
    }

    /**
     * @param page {HTMLElement}
     * @param element {HTMLElement}
     * @return {boolean}
     */
    removeOrphan(page, element) {
        const elementId = element.getAttribute('id');
        const parentElement = page.querySelector(
            `[data-item-element=${elementId}]`,
        );
        if (!parentElement) {
            removeInvisibleParagraphBreak(element);
            element.remove();
            return true;
        }
        return false;
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElement(element) {
        const page = getClosestPage(element);
        if (page && this.removeOrphan(page, element)) {
            return;
        }

        let textContainer = element.querySelector('.text');
        if (!textContainer) {
            const newElement = this.createEditorElement();
            element.replaceWith(newElement);
        } else {
            if (!textContainer.textContent) {
                textContainer.innerHTML = ZERO_WIDTH_NB_CHAR;
            }

            let markContainer = element.querySelector('.mark');
            if (!markContainer) {
                markContainer = document.createElement('div');
                markContainer.className = 'mark';
                markContainer.setAttribute('contentEditable', 'false');
                textContainer.before(markContainer);
            }
        }
        fixInvisibleParagraphBreak(element);
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBraille(element, flags, editorElements, brailleDocument) {
        const rawOutput = flags.includes(
            BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT,
        );
        const ignoreElement = flags.includes(
            BrailleFacilConversionFlag.IGNORE_CUSTOM_ELEMENT,
        );

        const markContainer = element.querySelector('.mark');
        const textContainer = element.querySelector('.text');

        const mark = markContainer.textContent
            .replaceAll('(', '`(')
            .replaceAll(')', '`)');

        let brailleData = convertElementToBraille(
            document.createTextNode(mark),
            editorElements,
            brailleDocument,
            [...flags],
        );

        brailleData += convertElementToBraille(
            textContainer,
            editorElements,
            brailleDocument,
            [...flags],
        );

        brailleData = markLines(brailleData, MARK_CHAR.RECOIL_BLOCK);

        if (!ignoreElement) {
            clearEditorElementLinesCount(element);
        }

        const { breaks, paragraphs } = getBrailleParagraphs(
            brailleData,
            brailleDocument,
        );

        if (!rawOutput) {
            let txtData = extractRecursively(
                document.createTextNode(mark),
                flags,
                editorElements,
                brailleDocument,
            );

            txtData += extractRecursively(
                textContainer,
                flags,
                editorElements,
                brailleDocument,
            );
            const textParagraphs = backPropagateBreaksToText(txtData, breaks);

            return textParagraphs.join('\r\n');
        }

        if (!ignoreElement) {
            breakParagraphsEditorElementContainer(element, breaks, true);
            setEditorElementLinesCount(element, paragraphs.length);
        }
        return markLines(paragraphs, MARK_CHAR.RAW_DATA).join('\r\n');
    }

    /**
     * @returns {string[]}
     */
    getContextMenu() {
        return [];
    }
}
