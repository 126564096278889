import LoggedContainer from '../components/logged/LoggedContainer';
import {
    EditorPdfFile,
    EditorPdfFile2x,
    EditorRename,
    EditorRename2x,
    EditorTxtFile,
    EditorTxtFile2x,
    EditorShare,
    EditorShare2x,
    EditorCopy,
    EditorCopy2x,
    EditorRemove,
    EditorRemove2x,
    DashboardMenu,
    DashboardMenu2x,
    ControlPainelMenuUsers,
    ControlPainelMenuUsers2x,
    ControlPainelMenuDictionary,
    ControlPainelMenuDictionary2x,
    EditorDocFile,
    EditorDocFile2x,
    EditorFigures,
    EditorFigures2x,
    EditorCover,
    EditorCover2x,
} from '../components/images';
import './Dashboard.scss';
import DocumentModal from './DocumentModal';
import { Link, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    DocumentsFilterEnum,
    DocumentsFilterEnumToString,
    DocumentsOrderByEnum,
    DocumentsOrderByEnumToString,
    OrientationEnum,
    RoleEnum,
    getDocumentUrl,
} from 'plataforma-braille-common';
import FieldSelect from '../components/FieldSelect';
import Loading from '../components/Loading';
import { getDocuments } from '../services/DocumentService';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import DocumentMiniature from './DocumentMiniature';
import ScrollPagination from '../components/ScrollPagination';
import { measureInPx } from '../util/Measure';
import ErrorLoading from '../components/ErrorLoading';
import ButtonFileModeView from '../components/ButtonFileModeView';
import {
    FileModeView,
    getFileModeView,
    modeViewToggle,
} from '../util/FileModeView';
import RenameDocumentModal from '../edit-document/RenameDocumentModal';
import ShareDocumentModal from '../edit-document/ShareDocumentModal';
import PropTypes from 'prop-types';
import {
    getUserRolesFromDocument,
    userCanExport,
    userCanRemove,
    userCanRenameDocument,
    userCanShareDocument,
} from '../edit-document/UserPermissions';
import { copyToClipboard } from '../util/CopyToClipboard';
import * as DocumentService from '../services/DocumentService';
import { removeStackedTags } from '../conversion/pdf/ImportPdf';
import {
    convertCoverElementToEditorPage,
    downloadFile,
    htmlToBrailleFacil,
} from '../conversion/txt/BrailleFacilUtil';
import { exportPdf } from '../conversion/pdf/ExportPdf';
import { getVersion } from '../version';
import DictionaryModal from '../dictionary/DictionaryModal';
import FiguresModal from '../figures/FiguresModal';
import CoversModal from '../covers/CoversModal';
import { EditorElements } from '../edit-document/editor-mods/modules/core/EditorElements';
import { exportDocx } from '../conversion/docx/ExportDocx';

function Dashboard({ newDocument, importPdf, dictionary, figures, covers }) {
    /**
     * @type {DocumentsFilter}
     */
    const [filter, setFilter] = useState({
        filter: DocumentsFilterEnum.LAST_DOCUMENTS,
        search: null,
        order: null,
        page: 0,
    });
    const [fileModeView, setFileModeView] = useState(getFileModeView());
    /**
     * @type {DocumentDto[]|null}
     */
    const defaultDoc = null;
    const [documents, setDocuments] = useState(defaultDoc);
    const [scrollControl, setScrollControl] = useState({
        records: 0,
        pageSize: 0,
    });
    const [loadingDocuments, setLoadingDocuments] = useState(
        new AbortController(),
    );

    const {
        user,
        showPopup,
        isAdmin,
        isEvaluator,
        isModerator,
        isPrintShop,
        setLoading,
        backendConnectionError,
        setConfirmModal,
    } = useContext(EnvironmentContext);
    const showDocumentModal = newDocument || importPdf;
    const showDictionaryModal = dictionary;
    const showFiguresModal = figures;
    const showCoversModal = covers;

    const navigate = useNavigate();
    /**
     * @type {React.MutableRefObject<HTMLDivElement | null>}
     */
    const documentsScrollRef = useRef(null);
    /**
     * @type {React.MutableRefObject<RenameDocumentModalFunctions | null>}
     */
    const renameDocumentModalRef = useRef(null);
    /**
     * @type {React.MutableRefObject<ShareDocumentModalFunctions | null>}
     */
    const shareDocumentModalRef = useRef(null);

    /**
     * @param obj {DocumentsFilter | object}
     */
    function updateFilter(obj) {
        setDocuments(null);
        setFilter((filter) => {
            obj.page = 0;
            return {
                ...filter,
                ...obj,
            };
        });
    }

    function orderOptions() {
        let options = [];
        for (const order in DocumentsOrderByEnum) {
            if (typeof order !== 'string') continue;
            const unit = DocumentsOrderByEnum[order];
            options.push({
                key: unit,
                value: DocumentsOrderByEnumToString(order),
            });
        }
        return options;
    }

    async function fetchDocuments() {
        if (loadingDocuments?.abort) {
            loadingDocuments.abort();
        }
        const abortController = new AbortController();
        try {
            setLoadingDocuments(abortController);
            const response = await getDocuments(filter, {
                signal: abortController.signal,
            });
            setDocuments((documents) => {
                documents = documents ?? [];
                const ids = new Set(documents.map((item) => item.id));
                return documents.concat(
                    response.records.filter((item) => {
                        return !ids.has(item.id);
                    }),
                );
            });
            setScrollControl({
                records: response.records.length,
                pageSize: response.pageSize,
            });
            setLoadingDocuments(null);
        } catch (e) {
            if (e.name === 'CanceledError') return;
            console.error('Failed to fetch documents', e);
            setLoadingDocuments(e);
        }
    }

    useEffect(() => {
        if (loadingDocuments?.abort) {
            loadingDocuments?.abort();
        }
        fetchDocuments().then();
    }, [filter]);

    /**
     * @param documentId {number}
     * @return {Promise<DocumentDto>}
     */
    async function fetchDocument(documentId) {
        setLoading(
            true,
            false,
            // I18N
            'Carregando documento...',
        );
        try {
            let doc = await DocumentService.getDocument(
                documentId,
                null,
                true,
                false,
            );
            doc = {
                ...doc,
                html: removeStackedTags(doc.html),
            };
            return doc;
        } finally {
            setLoading(false);
        }
    }

    /**
     * @param doc {DocumentDto}
     * @return {Promise<void>}
     */
    async function exportTxt(doc) {
        try {
            doc = await fetchDocument(doc.id);
            const html = doc.html;

            let coverPage;
            const regex = /<cover-page>.*?<\/cover-page>/;
            const matchCover = html.match(regex);

            if (matchCover) {
                coverPage = matchCover[0];
            }

            const htmlArray = html
                .replace(/<cover-page>.*?<\/cover-page>/, '')
                .split(/<document-break>.*?<\/document-break>/)
                .map((part) => part.trim())
                .filter(Boolean);

            const txtArray = htmlArray.map((htmlEl, index) => {
                let coverPageElement = '';
                let technicalSheetElement = '';

                if (coverPage) {
                    const convertedElement = convertCoverElementToEditorPage(
                        coverPage,
                        htmlArray.length,
                        index + 1,
                        doc.brailleCellRowCount,
                        doc.brailleCellColCount,
                    );

                    const matchConvertedElement = convertedElement.match(
                        /<editor-page>.*?<\/editor-page>/g,
                    );

                    coverPageElement =
                        matchConvertedElement[0] + matchConvertedElement[1]; //Cover and backCover
                    technicalSheetElement = matchConvertedElement[2] || ''; //technicalSheet
                }

                return htmlToBrailleFacil(
                    `${coverPageElement}${htmlEl}${technicalSheetElement}`,
                    new EditorElements(null),
                    doc,
                );
            });
            await downloadFile(doc.name, txtArray);
        } catch (e) {
            backendConnectionError('Fail to export TXT.', e);
        }
    }

    /**
     * @param doc {DocumentDto}
     * @return {Promise<{pages: HTMLElement[], release: function}>}
     */
    async function getRenderedPagesInvisibleViewport(doc) {
        /**
         * @type {HTMLIFrameElement}
         */
        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.left = '-999999px';
        iframe.style.opacity = '0';
        document.body.appendChild(iframe);
        iframe.srcdoc = `<link rel="stylesheet" type="text/css" href="${process.env.PUBLIC_URL}/assets/css/TinyMce.css?version=${getVersion()}">`;
        await new Promise((resolve) => {
            iframe.onload = async function () {
                iframe.contentWindow.document.body.innerHTML = doc.html;
                resolve();
            };
        });
        return {
            pages: [
                ...iframe.contentWindow.document.querySelectorAll(
                    'editor-page',
                ),
            ],
            release: () => {
                iframe.remove();
            },
        };
    }

    /**
     * @param doc {DocumentDto}
     * @return {Promise<void>}
     */
    async function exportPdfAction(doc) {
        let viewport;
        try {
            doc = await fetchDocument(doc.id);
            const viewport = await getRenderedPagesInvisibleViewport(doc);
            await exportPdf(doc, 1, viewport.pages, (current, total) => {
                setLoading(
                    true,
                    false,
                    'Gerando PDF...',
                    Math.round((current / total) * 100),
                );
            });
        } catch (e) {
            backendConnectionError('Fail to export PDF.', e);
        } finally {
            viewport?.release();
            setLoading(false);
        }
    }

    async function exportDocxAction(doc) {
        let viewport;
        try {
            doc = await fetchDocument(doc.id);
            const viewport = await getRenderedPagesInvisibleViewport(doc);
            await exportDocx(doc, viewport.pages, (current, total) => {
                setLoading(
                    true,
                    false,
                    'Gerando DOCX...',
                    Math.round((current / total) * 100),
                );
            });
        } catch (e) {
            backendConnectionError('Fail to export DOCX.', e);
        } finally {
            viewport?.release();
            setLoading(false);
        }
    }

    /**
     * @param document {BrailleDocument}
     */
    function removeDocument(document) {
        setConfirmModal({
            // I18N
            title: 'Remover documento',
            // I18N
            message: `Tem certeza que deseja remover o document "${document.name.trim()}"? <strong>Essa operação não poderá ser desfeita</strong>.`,
            show: true,
            onConfirm: async () => {
                // I18N
                setLoading(true, false, 'Removendo documento...');
                try {
                    await DocumentService.removeDocument(document.id);
                    setDocuments(
                        documents.filter((doc) => doc.id !== document.id),
                    );
                } catch (e) {
                    backendConnectionError('Fail to remove document.', e);
                } finally {
                    setLoading(false);
                }
            },
        });
    }

    /**
     * @param event {Event}
     */
    function showControlPainelPopupMenu(event) {
        /**
         * @type {HTMLElement}
         */
        let element = event.target;
        element = element.closest('a') ?? element;

        showPopup(element, [
            {
                // I18N
                description: 'Gestão de usuários',
                icon: [ControlPainelMenuUsers, ControlPainelMenuUsers2x],
                action: () => navigate('/gestao-usuarios'),
            },
            {
                // I18N
                description: 'Dicionário',
                icon: [
                    ControlPainelMenuDictionary,
                    ControlPainelMenuDictionary2x,
                ],
                action: () => navigate('/dicionario'),
            },
            {
                // I18N
                description: 'Figuras',
                icon: [EditorFigures, EditorFigures2x],
                action: () => navigate('/figuras'),
            },
            {
                // I18N
                description: 'Modelos de página',
                icon: [EditorCover, EditorCover2x],
                action: () => navigate('/modelos-de-pagina'),
            },
        ]);
    }

    /**
     * @param event {MouseEvent}
     * @param document {DocumentDto}
     */
    function showDocumentPopupMenu(event, document) {
        event.preventDefault();

        const userDocumentRoles = getUserRolesFromDocument(user, document);

        /**
         * @type {PopupMenuItem[]}
         */
        const menuItems = [];

        if (
            userCanRenameDocument(
                isAdmin,
                isEvaluator,
                isModerator,
                document.ownedByUser,
                document.readOnly,
                userDocumentRoles,
            )
        ) {
            menuItems.push({
                // I18N
                description: 'Renomear',
                icon: [EditorRename, EditorRename2x],
                action: () => {
                    renameDocumentModalRef.current?.showRenameDocumentModal(
                        document.id,
                        () => {
                            updateFilter({ page: 0 });
                        },
                    );
                },
            });
        }

        if (
            userCanExport(isAdmin, isEvaluator, isPrintShop, userDocumentRoles)
        ) {
            menuItems.push({
                // I18N
                description: 'Exportar TXT',
                icon: [EditorTxtFile, EditorTxtFile2x],
                action: () => exportTxt(document),
            });

            menuItems.push({
                // I18N
                description: 'Exportar PDF',
                icon: [EditorPdfFile, EditorPdfFile2x],
                action: () => exportPdfAction(document),
            });

            menuItems.push({
                // I18N
                description: 'Exportar DOCX',
                icon: [EditorDocFile, EditorDocFile2x],
                action: () => exportDocxAction(document),
            });
        }

        menuItems.push({
            // I18N
            description: 'Copiar URL',
            icon: [EditorCopy, EditorCopy2x],
            action: async () => {
                let publicUrl = process.env.PUBLIC_URL;
                if (!publicUrl) {
                    publicUrl =
                        window.location.protocol +
                        '//' +
                        window.location.hostname;
                    if (window.location.port) {
                        publicUrl += ':' + window.location.port;
                    }
                }

                const url = publicUrl + getDocumentUrl(document);
                await copyToClipboard(url);
            },
        });

        if (
            userCanShareDocument(
                isAdmin,
                isEvaluator,
                isModerator,
                isPrintShop,
                userDocumentRoles,
                document.status,
            )
        ) {
            menuItems.push({
                // I18N
                description: 'Compartilhar',
                icon: [EditorShare, EditorShare2x],
                action: () => {
                    shareDocumentModalRef.current?.showShareDocumentModal(
                        document,
                        null,
                    );
                },
            });
        }

        if (
            userCanRemove(
                isAdmin,
                isEvaluator,
                isModerator,
                document.ownedByUser,
            )
        ) {
            menuItems.push({
                // I18N
                description: 'Remover',
                icon: [EditorRemove, EditorRemove2x],
                action: () => removeDocument(document),
            });
        }

        showPopup(event.target, menuItems);
    }

    const documentMiniatures = useMemo(() => {
        const modeView = fileModeView;
        const scaleByViewMode = modeView === FileModeView.MINIATURE ? 0 : 10;
        const initialLoading = loadingDocuments && !documents;
        const noDocumentsFound =
            !loadingDocuments && documents && !documents.length;
        const maxWidthDocumentMiniature =
            modeView === FileModeView.MINIATURE ? 285 : 28.5;

        let miniatures = [];
        let globalMaxWidth = 0;

        if (documents) {
            for (const document of documents) {
                const width =
                    document.pageOrientation ===
                    OrientationEnum[OrientationEnum.LANDSCAPE]
                        ? document.pageHeight / scaleByViewMode
                        : document.pageWidth / scaleByViewMode;
                const templateWidthPx = measureInPx(
                    `${width}${document.pageMeasure}`,
                );
                if (templateWidthPx > globalMaxWidth)
                    globalMaxWidth = templateWidthPx;
            }
            for (const document of documents) {
                const params = {
                    ...document,
                    lockedByUser: document?.lockedByUser,
                    globalMaxWidth:
                        modeView === FileModeView.MINIATURE
                            ? globalMaxWidth
                            : 0,
                    maxWidth: maxWidthDocumentMiniature,
                };

                const documentLink = (
                    <Link
                        key={document.id}
                        onContextMenu={(event) =>
                            showDocumentPopupMenu(event, document)
                        }
                        to={getDocumentUrl(document)}
                    >
                        <DocumentMiniature {...params} modeView={modeView} />
                    </Link>
                );

                miniatures.push(documentLink);
            }
        }

        const errorLoading = () => {
            return (
                <ErrorLoading
                    onTryAgain={() => {
                        setFilter({ ...filter });
                    }}
                />
            );
        };

        const errorLoadingDocuments = loadingDocuments instanceof Error;

        const scrollPagination = () => {
            if (loadingDocuments && errorLoadingDocuments) {
                return errorLoading();
            } else if (scrollControl.records >= scrollControl.pageSize) {
                return (
                    <ScrollPagination
                        scrollElement={documentsScrollRef.current}
                        suspended={!!loadingDocuments}
                        onPageRequested={() => {
                            // if it has no document, is the first page, so no increment
                            const page =
                                (filter.page ?? 0) + (documents ? 1 : 0);
                            setFilter((filter) => {
                                filter.page = page;
                                return { ...filter };
                            });
                        }}
                    />
                );
            } else {
                return <></>;
            }
        };

        if (!miniatures.length && loadingDocuments && errorLoadingDocuments) {
            return (
                <div className={'documents-container'}>{errorLoading()}</div>
            );
        }

        return (
            <>
                {initialLoading && (
                    <div className={'documents-container'}>
                        {loadingDocuments ? <Loading /> : null}
                    </div>
                )}
                {!initialLoading && noDocumentsFound && (
                    <div className={'documents-container'}>
                        {/*I18N*/}
                        {'Nenhum documento encontrado'}
                    </div>
                )}
                <div
                    className={'documents-container'}
                    style={{
                        display: `${initialLoading || noDocumentsFound ? 'none' : 'unset'}`,
                    }}
                >
                    <div
                        ref={documentsScrollRef}
                        className={`scroll ${modeView}`}
                    >
                        <div className={modeView}>
                            {miniatures}
                            {scrollPagination()}
                        </div>
                    </div>
                </div>
            </>
        );
    }, [loadingDocuments, documents, filter, scrollControl, fileModeView]);

    const userAdmin = user?.roles?.includes(RoleEnum.ADMINISTRATOR);
    const userEvaluator = user?.roles?.includes(RoleEnum.EVALUATION);
    const userModerator = user?.roles?.includes(RoleEnum.MODERATION);
    const userCanCreateDocument = userAdmin || userEvaluator || userModerator;

    return (
        <LoggedContainer>
            <div className={'dashboard gd-container'}>
                <div className={'gd'}>
                    <div className={'gd-col-12--desktop'}>
                        <div className={'dashboard-container'}>
                            <div className={'controls'}>
                                {/*I18N*/}
                                {userCanCreateDocument && (
                                    <>
                                        <button
                                            onClick={() =>
                                                navigate('/novo-documento')
                                            }
                                        >
                                            {/*I18N*/}
                                            {'Novo documento'}
                                        </button>

                                        <button
                                            className={'primary'}
                                            onClick={() =>
                                                navigate(
                                                    '/novo-documento/importar-pdf',
                                                )
                                            }
                                        >
                                            {/*I18N*/}
                                            {'Importar PDF'}
                                        </button>
                                    </>
                                )}
                                <div className={'admin-controls'}>
                                    {userAdmin && (
                                        <a
                                            onClick={(e) =>
                                                showControlPainelPopupMenu(
                                                    e,
                                                )
                                            }
                                        >
                                            {/*I18N*/}
                                            {'Painel de controle'}
                                            <img
                                                src={`${DashboardMenu}`}
                                                srcSet={`${DashboardMenu} 1x, ${DashboardMenu2x} 2x`}
                                                alt={''}
                                            />
                                        </a>
                                    )}
                                </div>
                                {!userAdmin && (
                                    <div>
                                        {'Bem vindo, '}
                                        <strong>{user?.name.trim()}</strong>
                                    </div>
                                )}
                            </div>

                            <div className={'filter-controls'}>
                                <div>
                                    <a
                                        className={
                                            filter.filter ===
                                            DocumentsFilterEnum.LAST_DOCUMENTS
                                                ? 'selected'
                                                : ''
                                        }
                                    >
                                        {DocumentsFilterEnumToString(
                                            DocumentsFilterEnum.LAST_DOCUMENTS,
                                        )}
                                    </a>
                                    {/* disabled, but not abandoned yet */}
                                    {/*<a menuClassName={`disabled ${filter.filter === DocumentsFilterEnum.FAVORITES ? 'selected' : ''}`}>*/}
                                    {/*    <img*/}
                                    {/*        src={DashboardFavorite}*/}
                                    {/*        srcSet={`${DashboardFavorite} 1x, ${DashboardFavorite2x} 2x`}*/}
                                    {/*        // I18N*/}
                                    {/*        alt={'Favoritos'}*/}
                                    {/*    />*/}
                                    {/*    { DocumentsFilterEnumToString(DocumentsFilterEnum.FAVORITES) }*/}
                                    {/*</a>*/}
                                </div>
                                <div>
                                    <div className={'search-field'}>
                                        <input
                                            // I18N
                                            placeholder={'Buscar documento...'}
                                            className={'small search-field'}
                                            value={filter.search ?? ''}
                                            onChange={(e) =>
                                                updateFilter({
                                                    search: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <ButtonFileModeView
                                        viewMode={fileModeView}
                                        onClick={() =>
                                            setFileModeView(
                                                modeViewToggle(fileModeView),
                                            )
                                        }
                                    />
                                    <FieldSelect
                                        // I18N
                                        className={'transparent'}
                                        // I18N
                                        placeholder={'Ordenar por'}
                                        fieldGroup={false}
                                        options={orderOptions()}
                                        required={true}
                                        value={filter.order ?? '-1'}
                                        onChange={(e) =>
                                            updateFilter({
                                                order: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </div>
                            {documentMiniatures}
                        </div>

                        <DocumentModal
                            show={showDocumentModal}
                            importPdf={importPdf}
                            onClose={() => {
                                fetchDocuments().then();
                                navigate('/');
                            }}
                        />
                        <DictionaryModal
                            show={showDictionaryModal}
                            onClose={() => {
                                fetchDocuments().then();
                                navigate('/');
                            }}
                        />
                        <FiguresModal
                            show={showFiguresModal}
                            onClose={() => {
                                fetchDocuments().then();
                                navigate('/');
                            }}
                        />
                        <CoversModal
                            show={showCoversModal}
                            onClose={() => {
                                fetchDocuments().then();
                                navigate('/');
                            }}
                        />
                        <RenameDocumentModal ref={renameDocumentModalRef} />
                        <ShareDocumentModal ref={shareDocumentModalRef} />
                    </div>
                </div>
            </div>
        </LoggedContainer>
    );
}

Dashboard.propTypes = {
    newDocument: PropTypes.bool,
    importPdf: PropTypes.bool,
    dictionary: PropTypes.bool,
    figures: PropTypes.array,
    covers: PropTypes.array,
};

export default Dashboard;
