import {
    useEffect,
    useState,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';
import * as CoversService from '../services/CoverService';
import ScrollPagination from '../components/ScrollPagination';
import './CoversModalList.scss';
import ErrorLoading from '../components/ErrorLoading';
import CoversTable from './CoversTable';
import PropTypes from 'prop-types';

const STORAGE_KEY = 'coversFilter';

/**
 * @typedef {object} CoversModalListParams
 * @property {number | null} selected
 * @property {boolean} showingModal
 * @property {boolean | null} showPreviewBraille
 * @property {function(CoversDto)} onSelected
 * @property {function(CoversDto) | undefined} onDoubleClick
 */

/**
 * @typedef {object} CoversModalListFunctions
 * @property {function()} refresh
 */

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<CoversModalListParams>>}
 */
const CoversModalList = forwardRef(
    (
        {
            selected,
            onSelected,
            showingModal,
            showPreviewBraille,
            onDoubleClick = undefined,
        },
        ref,
    ) => {
        /**
         * @type {CoversDto[] | null}
         */
        const recordsInitialValue = null;
        const [records, setRecords] = useState(recordsInitialValue);
        const [loading, setLoading] = useState(null);
        const scrollControlInitialState = {
            records: 0,
            pageSize: 0,
        };
        const [scrollControl, setScrollControl] = useState(
            scrollControlInitialState,
        );
        /**
         * @typedef {object} FilterState
         * @property {string | null | undefined} search
         * @property {number} page
         */

        /**
         * @type {FilterState}
         */
        let filterInitialState = {
            search: '',
            page: 0,
        };
        try {
            const storedFilter = sessionStorage.getItem(STORAGE_KEY);
            if (storedFilter) {
                filterInitialState = {
                    ...filterInitialState,
                    ...JSON.parse(storedFilter),
                    page: 0,
                };
            }
        } catch (e) {}
        const [filter, setFilter] = useState(filterInitialState);
        const coversScrollRef = useRef();

        useEffect(() => {
            if (showingModal) {
                fetchCovers().then();
            }
        }, [filter]);

        async function fetchCovers() {
            if (loading?.abort) {
                loading.abort();
            }
            const abortController = new AbortController();
            try {
                setLoading(abortController);
                const data = await CoversService.getCovers(filter, {
                    signal: abortController.signal,
                });
                setRecords((prevCover) =>
                    data.page === 0
                        ? data.records
                        : [...prevCover, ...data.records],
                );
                setScrollControl({
                    records: data.records.length,
                    pageSize: data.pageSize,
                });
                setLoading(null);
            } catch (e) {
                if (e.name === 'CanceledError') return;
                console.error('Error to fetch covers.', e);
                setLoading(e);
            }
        }

        const updateFilter = (newFilter) => {
            setFilter((prevFilter) => {
                const updatedFilter = {
                    ...prevFilter,
                    ...newFilter,
                    page: 0,
                };
                sessionStorage.setItem(
                    STORAGE_KEY,
                    JSON.stringify(updatedFilter),
                );
                return updatedFilter;
            });
        };

        const error = loading instanceof Error;
        const scrollPagination = () => {
            if (loading && error) {
                return (
                    <ErrorLoading
                        onTryAgain={() => {
                            setFilter({ ...filter });
                        }}
                    />
                );
            } else if (
                scrollControl.records >= scrollControl.pageSize &&
                coversScrollRef.current
            ) {
                return (
                    <ScrollPagination
                        scrollElement={coversScrollRef.current}
                        suspended={!showingModal || !!loading}
                        onPageRequested={() => {
                            const page = (filter.page ?? 0) + (records ? 1 : 0);
                            setFilter((filter) => {
                                filter.page = page;
                                return { ...filter };
                            });
                        }}
                    />
                );
            } else {
                return null;
            }
        };

        function table() {
            if (records?.length === 0 && !loading) {
                return (
                    <div className={'status'}>
                        <p>{'Nenhum modelo de página encontrada'}</p>
                    </div>
                );
            } else {
                return (
                    <div ref={coversScrollRef}>
                        <CoversTable
                            records={records}
                            loadingRow={scrollPagination()}
                            selected={selected}
                            onSelected={onSelected}
                            showPreviewBraille={showPreviewBraille}
                            onDoubleClick={onDoubleClick}
                        />
                    </div>
                );
            }
        }

        useImperativeHandle(ref, () => ({
            refresh: () => {
                setFilter((prevFilter) => ({
                    ...prevFilter,
                    page: 0,
                }));
            },
        }));

        return (
            <div className={'covers-modal-list'}>
                <div>
                    <input
                        // I18N
                        placeholder={
                            'Pesquise pela descrição ou conteúdo do modelo de página'
                        }
                        className={'small search-field'}
                        value={filter.search ?? ''}
                        onChange={(e) =>
                            updateFilter({ ...filter, search: e.target.value })
                        }
                    />
                </div>

                <div className={'table-container'}>
                    <div className={'scroll'}>{table()}</div>
                </div>
            </div>
        );
    },
);

CoversModalList.displayName = 'CoversModalList';

CoversModalList.propTypes = {
    selected: PropTypes.number,
    onSelected: PropTypes.func.isRequired,
    showingModal: PropTypes.bool,
    showPreviewBraille: PropTypes.bool,
    onDoubleClick: PropTypes.func,
};

export default CoversModalList;
