import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import {
    generateId,
    getBrailleDocument,
    getClosestPage,
    isEditorElement,
    isInside,
} from '../EditorUtil';
import {
    createInvisibleParagraphBreak,
    fixInvisibleParagraphBreak,
    markLines,
} from '../EditorElements';
import { getCurrentPage, getPages } from '../PageManipulation';
import { extractRecursively } from '../../../../../conversion/txt/HtmlToBrailleFacil';
import { getPageCache } from '../Cache';
import { BrailleFacilConversionFlag } from '../../../../../conversion/txt/BrailleFacilConversionFlag';
import { MARK_CHAR } from '../../../../../conversion/braille/CharMap';
import {
    convertElementToBraille,
    getBrailleDataRealLength,
} from '../../../../../conversion/braille/HtmlToBraille';
import { getActiveEditor } from '../../../../EditDocument';

export const EDITOR_ELEMENT_HEADER = 'EDITOR_ELEMENT_HEADER';

/**
 * @typedef {object} HeaderData
 * @property {object} identification
 * @property {string | undefined} identification.value
 * @property {('left' | 'center' | 'right') | undefined} identification.textAlignment
 * @property {boolean | string | undefined} identification.suppress
 * @property {boolean | undefined} identification.affectAllPages
 * @property {number | undefined} identification.inkPagination
 * @property {number | undefined} identification.braillePagination
 * @property {boolean | undefined} eventCall
 */

/**
 * @param editor {EditorCustom | undefined | null}
 * @param data {HeaderData}
 * @return {HTMLElement}
 */
export function createEditorElementHeader(editor = null, data) {
    /**
     * @type {HTMLElement}
     */
    const editorElement = document.createElement('editor-element');
    const idPrefix = 'editor-element-header';
    const elementId = generateId(editor, idPrefix);
    editorElement.setAttribute('contentEditable', 'false');
    editorElement.setAttribute('type', 'header');
    editorElement.setAttribute('id', elementId);

    const containerDiv = document.createElement('div');
    containerDiv.setAttribute('class', 'container');
    containerDiv.setAttribute('contentEditable', 'false');

    const inkPaginationDiv = document.createElement('div');
    inkPaginationDiv.setAttribute('class', 'ink-pagination');
    inkPaginationDiv.setAttribute('contentEditable', 'false');
    if (data.identification.inkPagination) {
        inkPaginationDiv.setAttribute(
            'data-pagination-begin',
            `${data.identification.inkPagination}`,
        );
    }
    inkPaginationDiv.innerText = ZERO_WIDTH_NB_CHAR;
    containerDiv.appendChild(inkPaginationDiv);

    const identificationDiv = document.createElement('div');
    identificationDiv.setAttribute('class', 'identification');
    identificationDiv.setAttribute('contentEditable', 'true');
    identificationDiv.setAttribute(
        'data-text-alignment',
        data.identification.textAlignment || 'center',
    );
    identificationDiv.setAttribute(
        'data-suppress',
        data.identification.suppress?.toString() || 'false',
    );
    identificationDiv.innerText =
        data.identification.value || ZERO_WIDTH_NB_CHAR;
    containerDiv.appendChild(identificationDiv);

    const braillePaginationDiv = document.createElement('div');
    braillePaginationDiv.setAttribute('class', 'braille-pagination');
    braillePaginationDiv.setAttribute('contentEditable', 'false');
    if (data.identification.braillePagination) {
        inkPaginationDiv.setAttribute(
            'data-pagination-begin',
            `${data.identification.braillePagination}`,
        );
    }
    containerDiv.appendChild(braillePaginationDiv);

    editorElement.appendChild(containerDiv);
    return editorElement;
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementHeader(node) {
    if (!node) return false;
    return (
        isEditorElement(node) &&
        node.getAttribute('type')?.toLowerCase() === 'header'
    );
}

/**
 * @param node {HTMLElement | Node | null}
 * @returns {boolean}
 */
export function isInsideEditorElementHeader(node) {
    return isInside(node, (node) => {
        return isEditorElementHeader(node);
    });
}

/**
 * @param node {HTMLElement | Node | null}
 * @returns {HTMLElement | null}
 */
export function getClosestEditorElementHeader(node) {
    if (!node) return null;
    let walk = node;
    while (walk) {
        if (isEditorElementHeader(walk)) return walk;
        walk = walk.parentNode;
    }
    return node;
}

/**
 * this function prevents multiple headers in same page
 * @param page {HTMLElement | DocumentFragment}
 */
function preventMultipleHeaders(page) {
    const existingHeaders = page.querySelectorAll(
        'editor-element[type="header"]',
    );

    if (existingHeaders.length > 1) {
        existingHeaders.forEach(
            (element, index) => index >= 1 && page.removeChild(element),
        );
    }
}

/**
 * This function prevents changes to an element while the user is typing
 * @param editor {EditorCustom}
 * @param data {HeaderData}
 * @param existingHeader {HTMLElement}
 */
function preventChangesWhileTyping(editor, data, existingHeader) {
    if (data.eventCall) {
        const selectedNode = editor.selection.getNode();
        const closestEditorElementHeader =
            getClosestEditorElementHeader(selectedNode);
        if (closestEditorElementHeader?.id === existingHeader?.id) {
            return true;
        }
    }
}

/**
 * @param element {HTMLElement}
 * @param index {number}
 * @param dataPagination {string | number}
 */
const fillInkPagination = (element, index, dataPagination) => {
    const inkDataPagination = element.getAttribute('data-pagination-begin');
    if (dataPagination) {
        element.setAttribute('data-pagination-begin', `${dataPagination}`);
        element.innerText = (parseInt(dataPagination) + index).toString();
    } else if (inkDataPagination) {
        element.innerText = (parseInt(inkDataPagination) + index).toString();
    }
};

/**
 * @param element {HTMLElement}
 * @param data {HeaderData}
 */
const fillIdentification = (element, data) => {
    element.innerHTML = data.identification.value || element.innerHTML;
    element.setAttribute(
        'data-suppress',
        data.identification.suppress?.toString() ||
            element.getAttribute('data-suppress') ||
            'false',
    );
    element.setAttribute(
        'data-text-alignment',
        data.identification.textAlignment ||
            element.getAttribute('data-text-alignment') ||
            'center',
    );
};

/**
 @param editor {EditorCustom}
 * @param pageContainer {HTMLElement | DocumentFragment}
 * @param element {HTMLElement}
 * @param dataPaginationBegin {string | number}
 * @param page {HTMLElement}
 */
const fillBraillePagination = (
    editor,
    pageContainer,
    element,
    dataPaginationBegin,
    page,
) => {
    /**
     *
     * @type {ChildNode | HTMLElement}
     */
    // let previousElementPage = page.previousElementSibling;

    const previousElement = page.previousSibling;
    let previousElementPage =
        previousElement?.tagName === 'DOCUMENT-BREAK'
            ? previousElement.previousSibling
            : previousElement;

    if (!previousElementPage) {
        const firstBrailleNumber = dataPaginationBegin || element.innerText;
        //First element of document
        const pageNumber = parseInt(firstBrailleNumber).toString();
        if (Number(pageNumber)) {
            element.innerText = pageNumber;
        }
    } else {
        const previousPage =
            getPageCache(editor, previousElementPage) ?? previousElementPage;

        const previousHeader = previousPage?.querySelector(
            'editor-element[type="header"]',
        );

        /**
         *
         * @type {ChildNode | HTMLElement}
         */
        const previousBraillePagination = previousHeader?.querySelector(
            '.braille-pagination',
        );

        if (previousBraillePagination) {
            const previousNumber = parseInt(
                previousBraillePagination?.innerText,
            );
            if (!isNaN(previousNumber)) {
                //First element after a document-break and document marked as interpoint
                element.innerText =
                    previousElement?.tagName === 'DOCUMENT-BREAK' &&
                    getBrailleDocument(editor).interPoint &&
                    previousNumber % 2 === 1
                        ? `${previousNumber + 2}`
                        : `${previousNumber + 1}`;
            }
        }
    }
};

/**
 * @param editor {EditorCustom}
 * @param data {HeaderData}
 * @param pageContainer {HTMLElement | DocumentFragment}
 * @param pageIndex {number}
 * @param currentPageIndex {number}
 * @param existingHeader {HTMLElement}
 * @param page {HTMLElement}
 */
function fillExistingHeader(
    editor,
    data,
    pageContainer,
    pageIndex,
    currentPageIndex,
    existingHeader,
    page,
) {
    const identification = existingHeader.querySelector('.identification');
    const inkPagination = existingHeader.querySelector('.ink-pagination');
    const braillePagination = existingHeader.querySelector(
        '.braille-pagination',
    );

    if (currentPageIndex === pageIndex) {
        fillIdentification(identification, data);
    } else if (data.identification.affectAllPages) {
        fillIdentification(identification, data);
    }

    if (inkPagination) {
        fillInkPagination(
            inkPagination,
            pageIndex,
            data.identification.inkPagination,
        );
    }

    if (braillePagination) {
        fillBraillePagination(
            editor,
            pageContainer,
            braillePagination,
            data.identification.braillePagination ||
                braillePagination.getAttribute('data-pagination-begin'),
            page,
        );
    }

    if (!isEditorElementHeader(page.firstChild)) {
        pageContainer.removeChild(existingHeader);
        pageContainer.prepend(existingHeader);
    }
}

/**
 * @param that {EditorElementHeader}
 * @param editor {EditorCustom}
 * @param data {HeaderData}
 * @param pageContainer {HTMLElement | DocumentFragment}
 * @param pageIndex {number}
 * @param page {HTMLElement | DocumentFragment}
 */
function fillNewHeader(that, editor, data, pageContainer, pageIndex, page) {
    const editorElement = that.createEditorElement(editor, data);

    /**
     *
     * @type {ChildNode | HTMLElement}
     */
    const previousElement = page.previousSibling;
    let previousPage =
        previousElement?.tagName === 'DOCUMENT-BREAK'
            ? previousElement.previousSibling
            : previousElement;

    previousPage = getPageCache(editor, previousPage) ?? previousPage;

    const previousHeader = previousPage?.querySelector(
        'editor-element[type="header"]',
    );
    const previousIdentification =
        previousHeader?.querySelector('.identification');
    const previousInkPagination =
        previousHeader?.querySelector('.ink-pagination');
    const previousBraillePagination = previousHeader?.querySelector(
        '.braille-pagination',
    );

    if (previousIdentification) {
        editorElement.querySelector('.identification').innerHTML =
            previousIdentification.innerHTML;
    }

    if (previousInkPagination) {
        fillInkPagination(
            editorElement.querySelector('.ink-pagination'),
            pageIndex,
            previousInkPagination.getAttribute('data-pagination-begin'),
        );
    }

    if (previousBraillePagination) {
        fillBraillePagination(
            editor,
            pageContainer,
            editorElement.querySelector('.braille-pagination'),
            data.identification.braillePagination ||
                previousBraillePagination.getAttribute('data-pagination-begin'),
            page,
        );
    }

    const documentFragment = document.createDocumentFragment();
    documentFragment.appendChild(editorElement);
    documentFragment.appendChild(createInvisibleParagraphBreak());

    page.prepend(documentFragment);
}

/**
 * @implements {EditorElement}
 */
export class EditorElementHeader {
    constructor() {}

    /**
     * @param editor {EditorCustom}
     */
    initialize(editor) {
        const onPageAppended = () => {
            setTimeout(() => {
                /**
                 * @type {HeaderData}
                 */
                const newHeaderData = {
                    identification: {
                        value: '',
                        suppress: false,
                    },
                    eventCall: true,
                };
                this.updateAllPages(editor, newHeaderData);
            }, 0);
        };
        editor.on('pageAppended', onPageAppended);

        /**
         * @param e {EditorElementRemovedEvent}
         */
        const editorElementBeforeRemove = (e) => {
            e.preventRemove();
        };
        editor.on(
            `editorElementBeforeRemove@${this.getEditorElementType()}`,
            editorElementBeforeRemove,
        );
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'header';
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementHeader(node);
    }

    /**
     * @return {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.ink-pagination', '.identification', '.braille-pagination'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    isBlockingElement() {
        return true;
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     * @param data {HeaderData}
     * @return {HTMLElement}
     */
    createEditorElement(editor = null, data) {
        return createEditorElementHeader(editor, data);
    }

    /**
     * @returns {boolean}
     */
    isFixedToTop() {
        return true;
    }

    /**
     * @param editor {EditorCustom}
     * @param data {HeaderData}
     */
    updateAllPages(editor, data) {
        for (const page of getPages(editor)) {
            this.updatePage(editor, page, data);
        }
    }

    /**
     * @param {EditorCustom} editor
     * @param {HTMLElement} page
     * @param {HeaderData} data
     */
    updatePage(editor, page, data) {
        editor.undoManager.ignore(() => {
            const pages = getPages(editor);
            const currentPage = getCurrentPage(editor);
            const currentPageIndex =
                currentPage == null
                    ? pages.length - 1
                    : pages.indexOf(currentPage);

            const pageIndex = getPages(editor).indexOf(page);
            const pageContainer = getPageCache(editor, page) ?? page;

            const existingHeader = pageContainer.querySelector(
                'editor-element[type="header"]',
            );

            preventMultipleHeaders(pageContainer);
            if (preventChangesWhileTyping(editor, data, existingHeader)) {
                return;
            }

            if (existingHeader) {
                fillExistingHeader(
                    editor,
                    data,
                    pageContainer,
                    pageIndex,
                    currentPageIndex,
                    existingHeader,
                    page,
                );
            } else {
                fillNewHeader(
                    this,
                    editor,
                    data,
                    pageContainer,
                    pageIndex,
                    page,
                );
            }
        });
    }

    /**
     * @return {boolean}
     */
    insertElementAtCursor() {
        return false;
    }

    /**
     * @param container {HTMLElement}
     * @returns {HTMLElement[]}
     */
    getElementsInContainer(container) {
        const elements = [
            ...container.querySelectorAll('editor-element[type="header"]'),
        ];
        if (!elements.length) {
            /**
             * @type {HeaderData}
             */
            const data = {
                identification: {
                    value: '',
                    suppress: false,
                },
                eventCall: true,
            };
            this.updatePage(getActiveEditor(), getClosestPage(container), data);
        }
        return elements;
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElement(element) {
        /**
         * @type {HTMLElement}
         */
        let inkPagination = element.querySelector('.ink-pagination');
        if (!inkPagination) {
            inkPagination = document.createElement('div');
            inkPagination.className = 'ink-pagination';
            inkPagination.contentEditable = 'false';
            inkPagination.innerHTML = ZERO_WIDTH_NB_CHAR;
            element.querySelector('.container')?.appendChild(inkPagination);
        } else {
            inkPagination.contentEditable = 'false';
        }
        if (!inkPagination.innerText.trim().length) {
            inkPagination.innerHTML = ZERO_WIDTH_NB_CHAR;
        }

        /**
         *
         * @type {HTMLElement}
         */
        let identification = element.querySelector('.identification');
        if (!identification) {
            identification = document.createElement('div');
            identification.className = 'identification';
            identification.contentEditable = 'true';
            identification.innerHTML = ZERO_WIDTH_NB_CHAR;
            element.querySelector('.container')?.appendChild(identification);
        }
        if (!identification.innerText.trim().length) {
            identification.innerHTML = ZERO_WIDTH_NB_CHAR;
        }

        /**
         *
         * @type {HTMLElement}
         */
        let braillePagination = element.querySelector('.braille-pagination');
        if (!braillePagination) {
            braillePagination = document.createElement('div');
            braillePagination.className = 'braille-pagination';
            braillePagination.contentEditable = 'false';
            braillePagination.innerHTML = ZERO_WIDTH_NB_CHAR;
            element.querySelector('.container')?.appendChild(braillePagination);
        } else {
            braillePagination.contentEditable = 'false';
        }
        if (!braillePagination.innerText.trim().length) {
            braillePagination.innerHTML = ZERO_WIDTH_NB_CHAR;
        }
        fixInvisibleParagraphBreak(element);
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBraille(element, flags, editorElements, brailleDocument) {
        const rawOutput = flags.includes(
            BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT,
        );

        const inkPaginationContainer = element.querySelector('.ink-pagination');
        const identificationContainer =
            element.querySelector('.identification');
        const braillePaginationContainer = element.querySelector(
            '.braille-pagination',
        );

        const inkPagination = extractRecursively(
            inkPaginationContainer,
            flags,
            editorElements,
            brailleDocument,
        );

        const braillePagination = extractRecursively(
            braillePaginationContainer,
            flags,
            editorElements,
            brailleDocument,
        );

        const identification = extractRecursively(
            identificationContainer,
            flags,
            editorElements,
            brailleDocument,
        );

        /**
         *
         * @type {left | center | right | string}
         */
        const identificationTextAlign =
            identificationContainer.getAttribute('data-text-alignment') ||
            'center';

        const suppressIdentification =
            identificationContainer?.getAttribute('data-suppress') === 'true';

        let txt = '';
        if (
            (parseInt(braillePagination) % 2 && brailleDocument.interPoint) ||
            !brailleDocument.interPoint
        ) {
            if (!rawOutput) {
                const inkPaginationConverted = convertElementToBraille(
                    inkPaginationContainer,
                    editorElements,
                    brailleDocument,
                    [...flags],
                );

                const braillePaginationConverted = convertElementToBraille(
                    braillePaginationContainer,
                    editorElements,
                    brailleDocument,
                    [...flags],
                );

                let identificationConverted = '';
                if (!suppressIdentification) {
                    identificationConverted = convertElementToBraille(
                        identificationContainer,
                        editorElements,
                        brailleDocument,
                        [...flags],
                    );
                }

                if (inkPagination) {
                    txt += `<${inkPagination}>\r\n`;
                }

                if (!suppressIdentification && identification) {
                    let spaces =
                        brailleDocument.brailleCellColCount -
                        '<t >'.length -
                        getBrailleDataRealLength(inkPaginationConverted) -
                        getBrailleDataRealLength(braillePaginationConverted) -
                        getBrailleDataRealLength(identificationConverted);

                    if (spaces < 0) {
                        spaces = 0;
                    }

                    switch (identificationTextAlign) {
                        case 'left':
                            txt += `<t ${identification}${' '.repeat(spaces)}>\r\n`;
                            break;
                        case 'center':
                            txt += `<t ${identification}>\r\n`;
                            break;
                        case 'right':
                            txt += `<t ${' '.repeat(spaces)}${identification}>\r\n`;
                            break;
                    }
                }
                if (braillePagination) {
                    //Fixme: This line will be different on future Summary pages
                    // You will need to use <t*number> for Roman numerals
                    txt += `<t+${braillePagination}>\r\n`;
                }
                txt += '\r\n';
                return txt;
            } else {
                const inkPaginationConverted = convertElementToBraille(
                    inkPaginationContainer,
                    editorElements,
                    brailleDocument,
                    flags,
                );

                const braillePaginationConverted = convertElementToBraille(
                    braillePaginationContainer,
                    editorElements,
                    brailleDocument,
                    flags,
                );

                let identificationConverted = '';
                if (!suppressIdentification) {
                    identificationConverted = convertElementToBraille(
                        identificationContainer,
                        editorElements,
                        brailleDocument,
                        flags,
                    );
                }

                let spaceAroundIdentification =
                    brailleDocument.brailleCellColCount -
                    getBrailleDataRealLength(inkPaginationConverted) -
                    getBrailleDataRealLength(braillePaginationConverted);

                let identificationMaxLength = spaceAroundIdentification - 2;
                let identificationLength = getBrailleDataRealLength(
                    identificationConverted,
                );
                spaceAroundIdentification -= identificationLength;

                if (identificationLength > identificationMaxLength) {
                    identificationConverted = identificationConverted.substring(
                        0,
                        identificationMaxLength,
                    );
                    spaceAroundIdentification = 2;
                }

                txt += `${inkPaginationConverted}`;
                switch (identificationTextAlign) {
                    case 'left':
                        txt += ` ${identificationConverted}${' '.repeat(Math.ceil(spaceAroundIdentification - 1))}`;
                        break;
                    case 'center':
                        txt += ' '.repeat(
                            Math.floor(spaceAroundIdentification / 2),
                        );
                        txt += `${identificationConverted}${' '.repeat(Math.ceil(spaceAroundIdentification / 2))}`;
                        break;
                    case 'right':
                        txt += ' '.repeat(
                            Math.floor(spaceAroundIdentification - 1),
                        );
                        txt += `${identificationConverted} `;
                        break;
                }
                txt += `${braillePaginationConverted}`;

                return markLines(txt, MARK_CHAR.RAW_DATA);
            }
        } else {
            if (!rawOutput) {
                if (inkPagination) {
                    return `<${inkPagination}>\r\n<P>\r\n\r\n`;
                } else {
                    return '<P>\r\n\r\n';
                }
            } else {
                return '';
            }
        }
    }

    /**
     * @returns {string[]}
     */
    getContextMenu() {
        return ['customContextMenuAddParagraphBreakBellow'];
    }
}

// Temporarily disable (#64202)
// registerEditorElement(EDITOR_ELEMENT_HEADER, new EditorElementHeader());
