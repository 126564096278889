import { registerEditorElement } from './Instances';
import {
    generateId,
    getBrailleDocument,
    getClosestPage,
    isEditorElement,
    isInside,
} from '../EditorUtil';
import {
    createInvisibleParagraphBreak,
    fixInvisibleParagraphBreak,
    markLines,
    removeInvisibleParagraphBreak,
} from '../EditorElements';
import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import { MARK_CHAR } from '../../../../../conversion/braille/CharMap';
import { BrailleFacilConversionFlag } from '../../../../../conversion/txt/BrailleFacilConversionFlag';
import { getElementOfPageNotFixed } from '../../ExcessLinesControlModule';
import { EditorElementFootnoteMark } from './EditorElementFootnoteMark';
import {
    EditorElementFootnoteItem,
    isInsideEditorElementFootnoteItem,
} from './EditorElementFootnoteItem';

export const EDITOR_ELEMENT_FOOTNOTE = 'EDITOR_ELEMENT_FOOTNOTE';
export const EDITOR_ELEMENT_FOOTNOTE_ITEM = 'EDITOR_ELEMENT_FOOTNOTE_ITEM';
export const EDITOR_ELEMENT_FOOTNOTE_MARK = 'EDITOR_ELEMENT_FOOTNOTE_MARK';

export function getListSubmenuItems(editor) {
    const { editorElements } = editor.custom.coreModule;
    return [
        {
            type: 'menuitem',
            // I18N
            text: 'Notas',
            onAction: function () {
                editorElements.insertElementAtCursor(
                    EDITOR_ELEMENT_FOOTNOTE_MARK,
                );
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Letras estrangeiras',
            onAction: function () {
                editorElements.insertElementAtCursor(
                    EDITOR_ELEMENT_FOOTNOTE_MARK,
                    {
                        foreignLetters: true,
                    },
                );
            },
        },
    ];
}

/**
 * @param page {HTMLElement}
 * @returns {HTMLElement}
 */
export function getFootnoteElementFromPage(page) {
    return page.querySelector('editor-element[type="footnote"]');
}

/**
 * @param node {Node | HTMLElement}
 * @returns {boolean}
 */
function isEditorElementFootnote(node) {
    return node?.getAttribute && node.getAttribute('type') === 'footnote';
}

/**
 * @param node {Node}
 * @returns {boolean}
 */
function isInsideEditorElementFootnote(node) {
    return isInside(
        node,
        (node) => isEditorElement(node) && isEditorElementFootnote(node),
    );
}

/**
 * @param editorElements {EditorElements}
 * @param page {HTMLElement}
 * @param footnoteElement {HTMLElement}
 */
function appendFootnoteInPage(editorElements, page, footnoteElement) {
    let lastElement = getElementOfPageNotFixed(editorElements, page, false);
    if (!lastElement && footnoteElement.parentElement !== page) {
        page.append(footnoteElement);
    } else if (lastElement?.nextElementSibling !== footnoteElement) {
        lastElement?.after(footnoteElement);
    }
}

/**
 * @implements {EditorElement}
 */
export class EditorElementFootnote {
    /**
     * @type {null | EditorCustom}
     */
    editor;
    /**
     * @type {null | EditorElements}
     */
    editorElements = null;

    /**
     * @returns {EditorElementFootnoteItem | null}
     */
    getEditorElementFootnoteItem() {
        return (
            this.editorElements.getEditorElementInstance(
                EDITOR_ELEMENT_FOOTNOTE_ITEM,
            ) ?? null
        );
    }

    /**
     * @param editor {EditorCustom}
     * @param editorElements {EditorElements}
     */
    initialize(editor, editorElements) {
        this.editor = editor;
        this.editorElements = editorElements;

        /**
         * @param e {EditorElementRemovedEvent}
         */
        const editorElementBeforeRemove = (e) => {
            e.preventRemove();
        };
        editor.on(
            `editorElementBeforeRemove@${this.getEditorElementType()}`,
            editorElementBeforeRemove,
        );
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'footnote';
    }

    /**
     * @param node {Node}
     * @returns {boolean}
     */
    isNodeInsideElement(node) {
        return (
            !isInsideEditorElementFootnoteItem(node) &&
            isInsideEditorElementFootnote(node)
        );
    }

    /**
     * @returns {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.text'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    isBlockingElement() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    isFixedToBottom() {
        return true;
    }

    /**
     * @param editor {EditorCustom | undefined}
     * @return {HTMLElement}
     */
    createEditorElement(editor = undefined) {
        const editorElement = document.createElement('editor-element');
        const idPrefix = 'editor-element-footnote';
        const elementId = generateId(editor, idPrefix);

        editorElement.setAttribute('type', 'footnote');
        editorElement.setAttribute('id', elementId);
        editorElement.setAttribute('contentEditable', 'false');

        const textContainer = document.createElement('div');
        textContainer.className = 'text';
        textContainer.setAttribute('contentEditable', 'false');
        textContainer.innerHTML = ZERO_WIDTH_NB_CHAR;
        editorElement.appendChild(textContainer);

        return editorElement;
    }

    /**
     * @return {boolean}
     */
    insertElementAtCursor() {
        throw new Error('This element cannot be inserted at cursor');
    }

    /**
     * @param element {HTMLElement}
     */
    fixMarginsWithDocument(element) {
        if (!isEditorElementFootnote(element)) {
            console.warn('Not a valid footnote element.', element);
            return;
        }
        const brailleDocument = getBrailleDocument(this.editor);

        if (brailleDocument.convertedToBraille) {
            let { inkPageMarginLeft, inkPageMarginRight, inkPageMarginBottom } =
                brailleDocument;

            element.style.paddingLeft = `${inkPageMarginLeft}mm`;
            element.style.paddingRight = `${inkPageMarginRight}mm`;
            element.style.paddingBottom = `${inkPageMarginBottom}mm`;
        } else {
            const page = getClosestPage(element);
            const computedStyles = window.getComputedStyle(page);
            const inkPageMarginLeft =
                computedStyles.getPropertyValue('padding-left');
            const inkPageMarginRight =
                computedStyles.getPropertyValue('padding-right');
            const inkPageMarginBottom =
                computedStyles.getPropertyValue('padding-top');

            element.style.paddingLeft = inkPageMarginLeft;
            element.style.paddingRight = inkPageMarginRight;
            element.style.paddingBottom = inkPageMarginBottom;

            // for some reason, the invisible brs are visible at this moment and
            // the height is calculated wrong, after reconstruction in revision module
            for (const item of this.getEditorElementFootnoteItem().getElementsInContainer(
                element,
            )) {
                fixInvisibleParagraphBreak(item);
            }
            const height = element.offsetHeight;
            page.style.paddingBottom = `${height}px`;
        }
    }

    /**
     * @param page {HTMLElement}
     * @returns {boolean}
     */
    insertElementAtPage(page) {
        if (page.querySelector('editor-element[type="footnote"]')) {
            // only one to page
            return false;
        }

        this.editor.undoManager.transact(() => {
            const editorElement = this.createEditorElement(this.editor);
            page.appendChild(editorElement);
            page.appendChild(createInvisibleParagraphBreak());
            this.fixMarginsWithDocument(editorElement);
        });
        return true;
    }

    /**
     * @param page {HTMLElement}
     */
    removeElementFromPage(page) {
        const elements = this.getElementsInContainer(page);
        for (const element of elements) {
            element.remove();
        }
    }

    /**
     * @param container {HTMLElement}
     * @returns {HTMLElement[]}
     */
    getElementsInContainer(container) {
        return [
            ...container.querySelectorAll('editor-element[type="footnote"]'),
        ];
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElement(element) {
        const page = getClosestPage(element);
        const items =
            this.getEditorElementFootnoteItem().getElementsInContainer(page);

        if (!items.length) {
            removeInvisibleParagraphBreak(element);
            element.remove();
            return;
        }

        let textContainer = element.querySelector('.text');
        if (!textContainer) {
            const newElement = this.createEditorElement();
            element.replaceWith(newElement);
        } else {
            if (!textContainer.textContent) {
                textContainer.innerHTML = ZERO_WIDTH_NB_CHAR;
            }
        }
        fixInvisibleParagraphBreak(element);
        this.fixMarginsWithDocument(element);
        if (page) {
            appendFootnoteInPage(this.editorElements, page, element);
        }
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBraille(element, flags, editorElements, brailleDocument) {
        const rawConversion = flags.includes(
            BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT,
        );

        const editorElementFootnoteItem = this.getEditorElementFootnoteItem();

        let itemsData = '';
        const items = element.querySelectorAll(
            'editor-element[type="footnote-item"]',
        );
        for (const item of items) {
            itemsData +=
                editorElementFootnoteItem.convertToBraille(
                    item,
                    flags,
                    editorElements,
                    brailleDocument,
                ) ?? '';
            itemsData += '\r\n';
        }
        itemsData = itemsData.trimEnd();

        if (!rawConversion) {
            /**
             * @type {Node | HTMLElement}
             */
            let previousSibling = element.previousSibling;
            while (
                previousSibling?.tagName !== 'BR' &&
                previousSibling.textContent?.trim() === ''
            ) {
                previousSibling = previousSibling?.previousSibling;
            }

            let output = previousSibling?.tagName !== 'BR' ? '\r\n' : '';
            output += '<R+>\r\n';
            output += itemsData;
            if (!output.endsWith('\n')) {
                output += '\r\n';
            }
            output += '<R->\r\n';
            return output;
        }

        return (
            MARK_CHAR.FOOT_PAGE_MARK +
            markLines(
                ':'.repeat(brailleDocument.brailleCellColCount),
                MARK_CHAR.RAW_DATA,
            ) +
            '\r\n' +
            itemsData
        );
    }

    /**
     * @returns {string[]}
     */
    getContextMenu() {
        return [];
    }

    /**
     * @param element {HTMLElement}
     * @returns {HTMLElement[]}
     */
    getInnerContextContainers(element) {
        const mainContainer = element.querySelector('.text');
        return [...mainContainer.querySelectorAll('.text')];
    }
}

registerEditorElement(EDITOR_ELEMENT_FOOTNOTE, new EditorElementFootnote());
registerEditorElement(
    EDITOR_ELEMENT_FOOTNOTE_ITEM,
    new EditorElementFootnoteItem(),
);
registerEditorElement(
    EDITOR_ELEMENT_FOOTNOTE_MARK,
    new EditorElementFootnoteMark(),
);
