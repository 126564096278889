import CloseModalButton from '../components/modal/CloseModalButton';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as CoverService from '../services/CoverService';
import CoversModalList from './CoversModalList';
import CoversModalForm from './CoversModalForm';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import { SaveCoverValidationErrorEnumTxt } from 'plataforma-braille-common';
import './CoversModal.scss';
import PropTypes from 'prop-types';

/**
 * @typedef {object} FormData
 * @property {boolean | null | undefined} newCover
 * @property {string} description
 * @property {string} cover
 * @property {string} backCover
 * @property {string} technicalSheet
 */

/**
 * @typedef {object} FormError
 * @property {string | null | undefined} description
 * @property {string | null | undefined} cover
 * @property {string | null | undefined} backCover
 * @property {string | null | undefined} technicalSheet
 */

/**
 * @param show {boolean}
 * @param onClose {function}
 * @returns {JSX.Element}
 * @constructor
 */
function CoversModal({ show, onClose }) {
    const { setConfirmModal, setInfoModal, backendConnectionError } =
        useContext(EnvironmentContext);

    /**
     * @type {React.MutableRefObject<CoversModalListFunctions | null>}
     */
    const coversModalListRef = useRef(null);
    /**
     * @type {FormData | null}
     */
    const formDataInitialValue = null;
    const [formData, setFormData] = useState(formDataInitialValue);
    const initialFormError = {};
    const [formError, setFormError] = useState(initialFormError);
    const [validateOnChange, setValidateOnChange] = useState(false);
    /**
     * @type {string | null}
     */
    const [selectedCover, setSelectedCover] = useState(null);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    useEffect(() => {
        if (validateOnChange) hasValidationError();
    }, [formData]);

    /**
     * @param formData {FormData | null}
     */
    function updateFormData(formData) {
        if (!formData) {
            setFormData(null);
        } else {
            setFormData((prevState) => {
                if (
                    prevState?.newCover === true &&
                    formData?.newCover === false
                ) {
                    return null;
                }
                return { ...prevState, ...formData };
            });
        }
        if (formData?.newCover) {
            setSelectedCover(null);
        }
    }

    /**
     * @param formData {FormData}
     * @return {{hasErrors: boolean, errors: FormError}}
     */
    function hasValidationErrorDocument(formData) {
        let errors = {};
        let hasErrors = false;

        if (
            !!formData &&
            (!formData.description || formData.description.trim() === '')
        ) {
            errors.description = 'Campo obrigatório.';
            hasErrors = true;
        }

        if (!!formData && (!formData.cover || formData.cover.trim() === '')) {
            errors.cover = 'Campo obrigatório.';
            hasErrors = true;
        }

        if (
            !!formData &&
            (!formData.backCover || formData.backCover.trim() === '')
        ) {
            errors.backCover = 'Campo obrigatório.';
            hasErrors = true;
        }

        return { hasErrors, errors };
    }

    // I18N
    const modalTitle = 'Modelos de página';

    function hasValidationError(document = true) {
        const { hasErrors, errors } = hasValidationErrorDocument(formData);
        if (document) setValidateOnChange(true);
        setFormError(errors);
        return hasErrors;
    }

    async function fetchCovers() {
        coversModalListRef.current?.refresh();
    }

    async function save() {
        if (hasValidationError()) return;

        let title;
        const covers = {
            description: formData.description,
            cover: formData.cover,
            backCover: formData.backCover,
            technicalSheet: formData.technicalSheet,
        };

        setSaveLoading(true);
        try {
            let message;
            if (formData.newCover) {
                // I18N
                title = 'Novo modelo de página';
                // I18N
                message = 'Modelo de página adicionado com sucesso!';
                await CoverService.createCover(covers);
            } else {
                // I18N
                title = 'Editar modelo de página';
                // I18N
                message = 'Modelo de página atualizado com sucesso!';
                await CoverService.editCover(selectedCover, covers);
            }

            setInfoModal({
                title,
                message,
                show: true,
                onClose: () => {
                    fetchCovers().then();
                    updateFormData(null);
                    setSelectedCover(null);
                },
            });
        } catch (e) {
            backendConnectionError(
                'Fail to save cover',
                e,
                null,
                title,
                SaveCoverValidationErrorEnumTxt,
            );
        } finally {
            setSaveLoading(false);
        }
    }

    function remove() {
        setConfirmModal({
            // I18N
            title: 'Remover modelo de página',
            message:
                'Deseja remover realmente o modelo de página? <strong>Essa operação não poderá ser desfeita</strong>.',
            show: true,
            onConfirm: async () => {
                setRemoveLoading(true);
                try {
                    await CoverService.deleteCover(selectedCover);
                    setInfoModal({
                        // I18N
                        title: 'Remover modelo de página',
                        // I18N
                        message: 'Modelo de página removido com sucesso!',
                        show: true,
                        onClose: () => {
                            fetchCovers().then();
                            updateFormData(null);
                            setSelectedCover(null);
                        },
                    });
                } catch (e) {
                    backendConnectionError('Fail to remove cover', e);
                } finally {
                    setRemoveLoading(false);
                }
            },
        });
    }

    const documentForm = useMemo(() => {
        return (
            <CoversModalForm
                formError={formError}
                formData={formData}
                onSave={save}
                onRemove={remove}
                onFormUpdate={updateFormData}
                saveLoading={saveLoading}
                removeLoading={removeLoading}
            />
        );
    }, [formData, formError, saveLoading, removeLoading]);

    return (
        <div
            className={`modal default-modal cover-modal ${show ? 'show' : ''}`}
        >
            <div className={'backdrop'} />
            <div className={'container'}>
                <CloseModalButton onClick={onClose} />
                <h2 className={'compact'}>{modalTitle}</h2>
                <div className={'gd-inner table-form-container'}>
                    <div className={'gd-col gd-col-3--desktop'}>
                        <CoversModalList
                            ref={coversModalListRef}
                            selected={selectedCover}
                            onSelected={(selected) => {
                                setSelectedCover(selected.id);
                                setFormData({
                                    newCover: false,
                                    id: selected.id,
                                    description: selected.description,
                                    cover: selected.cover,
                                    backCover: selected.backCover,
                                    technicalSheet: selected.technicalSheet,
                                });
                            }}
                            showingModal={show}
                        />
                    </div>
                    <div className={'gd-col gd-col-7--desktop'}>
                        <div className={'form-container'}>{documentForm}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

CoversModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};

export default CoversModal;
