import {
    generateId,
    getClosestPage,
    isEditorElement,
    isInside,
} from '../EditorUtil';
import { getCaretPosition } from '../CaretPath';
import { elementCanBeInsertedAtSelection } from '../EditorElements';
import { getCurrentPage } from '../PageManipulation';
import {
    EDITOR_ELEMENT_FOOTNOTE,
    EDITOR_ELEMENT_FOOTNOTE_ITEM,
    getFootnoteElementFromPage,
} from './EditorElementFootnote';

// I18N
const acuteAccents = ['á', 'é', 'í', 'ó', 'ú', 'ý'];
const graveAccents = ['à', 'è', 'ì', 'ò', 'ù'];
const circumflexAccents = ['â', 'ê', 'î', 'ô', 'û'];
const diaeresisAccents = ['ä', 'ë', 'ï', 'ö', 'ü', 'ÿ'];
const tildeAccents = ['ã', 'õ', 'ñ'];
const foreignLetterBrailleMap = {
    "'": '*',
    '`': '?',
    '^': '^',
    '"': '¬',
    '~': '~',
};
const accentsPtBrNameMap = {
    // I18N
    "'": 'acento agudo',
    // I18N
    '`': 'acento grave',
    // I18N
    '^': 'acento circunflexo',
    // I18N
    '"': 'trema',
    // I18N
    '~': 'til',
};
const accentsMap = {
    "'": acuteAccents,
    '`': graveAccents,
    '^': circumflexAccents,
    '"': diaeresisAccents,
    '~': tildeAccents,
};

/**
 * @param node {Node}
 * @returns {boolean}
 */
function isInsideEditorElementFootnoteMark(node) {
    return isInside(
        node,
        (node) => isEditorElement(node) && isEditorElementFootnoteMark(node),
    );
}

/**
 * @param node {Node | HTMLElement}
 * @returns {boolean}
 */
function isEditorElementFootnoteMark(node) {
    return node?.getAttribute && node.getAttribute('type') === 'footnote-mark';
}

/**
 * @param text {string}
 * @returns {{text: string[], accent: string}}
 */
function separateAccent(text) {
    for (const [accent, chars] of Object.entries(accentsMap)) {
        for (const char of chars) {
            const charIndex = text.indexOf(char);
            if (charIndex !== -1) {
                const baseChar = char
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '');
                return {
                    text: [
                        text.slice(0, charIndex),
                        baseChar,
                        text.slice(charIndex + 1),
                    ],
                    accent,
                };
            }
        }
    }
    return { text: [text], accent: '' }; // Return original text and empty accent if no accented character is found
}

/**
 * This function is slow, does not keep a map in memory
 * Change if usage will be frequent
 * @param text {string}
 * @returns {string}
 */
function revertAccent(text) {
    const baseCharMap = {};
    for (const [accent, chars] of Object.entries(accentsMap)) {
        chars.forEach((char) => {
            const baseChar = char
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
            baseCharMap[baseChar + accent] = char;
            if (foreignLetterBrailleMap[accent]) {
                baseCharMap[foreignLetterBrailleMap[accent] + baseChar] = char;
            }
        });
    }
    return text.replace(/([*?¬~^])(\w)/gi, (match, char, accent) => {
        return baseCharMap[char + accent] || match;
    });
}

/**
 * @implements {EditorElement}
 */
export class EditorElementFootnoteMark {
    /**
     * @type {null | EditorCustom}
     */
    editor = null;
    /**
     * @type {null | EditorElements}
     */
    editorElements = null;

    /**
     * @type {null | HTMLElement}
     */
    lastElementSelectedContextMenu = null;

    /**
     * @return {EditorElementFootnote | null}
     */
    getEditorElementFootnote() {
        return (
            this.editorElements.getEditorElementInstance(
                EDITOR_ELEMENT_FOOTNOTE,
            ) ?? null
        );
    }

    /**
     * @return {EditorElementFootnoteItem | null}
     */
    getEditorElementFootnoteItem() {
        return (
            this.editorElements.getEditorElementInstance(
                EDITOR_ELEMENT_FOOTNOTE_ITEM,
            ) ?? null
        );
    }

    /**
     * @param page {HTMLElement}
     * @private
     */
    _elementRemoved(page) {
        const footnoteElement = getFootnoteElementFromPage(page);
        const editorElementFootnoteItem = this.getEditorElementFootnoteItem();
        if (!editorElementFootnoteItem) return;
        for (const element of editorElementFootnoteItem.getElementsInContainer(
            page,
        )) {
            editorElementFootnoteItem.removeOrphan(page, element);
        }
        editorElementFootnoteItem.reorderElements(footnoteElement, page);
    }

    /**
     * @param element {HTMLElement}
     * @private
     */
    _beforeRemove(element) {
        const foreignAccent = element.getAttribute('data-foreign-accent');
        if (!foreignAccent) return;

        let textSibling = null;
        /**
         * @type {Element | Node}
         */
        let walk = element.previousSibling;
        while (walk) {
            if (walk?.tagName === 'BR') break;
            if (walk.textContent.indexOf(foreignAccent) !== -1) {
                textSibling = walk;
                break;
            }
            walk = walk.previousSibling;
        }

        if (textSibling) {
            if (textSibling.textContent.indexOf(foreignAccent) === -1) {
                console.debug(
                    `Cannot revert foreign accent from text ${textSibling.textContent}`,
                );
            } else {
                textSibling.textContent = revertAccent(textSibling.textContent);
            }
        }
    }

    /**
     * @param editor {EditorCustom}
     * @param editorElements {EditorElements}
     */
    initialize(editor, editorElements) {
        this.editor = editor;
        this.editorElements = editorElements;

        const self = this;
        this.editor.on(
            `editorElementBeforeRemove@${this.getEditorElementType()}`,
            (e) => {
                /**
                 * @type {HTMLElement}
                 */
                const element = e?.element;
                self._beforeRemove(element);
            },
        );

        this.editor.on(
            `editorElementRemoved@${this.getEditorElementType()}`,
            (e) => {
                const page = e?.page;
                if (page) {
                    self._elementRemoved(page);
                }
            },
        );

        editor.ui.registry.addNestedMenuItem(
            'customEditorElementFootnoteMarkChangeModel',
            {
                // I18N
                text: 'Alterar modelo',
                icon: 'footnote',
                getSubmenuItems: function () {
                    const element = self.lastElementSelectedContextMenu;
                    const currentMark = element.textContent.trim();
                    const page = getClosestPage(element);
                    if (!page) return [];
                    return self.getUniqueMarks(page).map((mark) => ({
                        type: 'togglemenuitem',
                        text: mark,
                        onSetup: (api) => {
                            api.setActive(mark === currentMark);
                        },
                        onAction: () => {
                            const refreshedElement = self.editor.dom.get(
                                element.getAttribute('id'),
                            );
                            self.changeMarkModel(refreshedElement, page, mark);
                        },
                    }));
                },
            },
        );
    }

    /**
     * @param element {HTMLElement}
     * @param page {HTMLElement}
     * @param newMark {string}
     */
    changeMarkModel(element, page, newMark) {
        const editorElementFootnoteItem = this.getEditorElementFootnoteItem();
        if (!editorElementFootnoteItem) return;
        /**
         * @type {null | HTMLElement}
         */
        let footnoteItem = null;
        for (const elementItem of editorElementFootnoteItem.getElementsInContainer(
            page,
        )) {
            const elementItemMarkContainer = elementItem.querySelector('.mark');
            if (elementItemMarkContainer.textContent.trim() === newMark) {
                footnoteItem = elementItem;
                break;
            }
        }
        if (!footnoteItem) {
            const footnoteElement = getFootnoteElementFromPage(page);

            footnoteItem = this.getEditorElementFootnoteItem().addFootnoteItem(
                footnoteElement,
                newMark,
                '',
                element.getAttribute('data-foreign-accent'),
            );
        }
        const caretPosition = getCaretPosition(this.editor);
        this.editor.undoManager.transact(() => {
            const elementItemId = footnoteItem.getAttribute('id');
            const markContainer = element.querySelector('.text');
            markContainer.innerText = newMark;
            element.setAttribute('data-item-element', elementItemId);
        });

        /**
         * @type {PageDataChangedEvent}
         */
        const pageDataChangedEvent = {
            caretPosition,
        };
        this.editor.fire('pageDataChanged', pageDataChangedEvent);
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'footnote-mark';
    }

    /**
     * @param node {Node}
     * @returns {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementFootnoteMark(node);
    }

    /**
     * @returns {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.text'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    isBlockingElement() {
        return false;
    }

    /**
     * @param editor {EditorCustom | undefined}
     * @return {HTMLElement}
     */
    createEditorElement(editor = undefined) {
        const editorElement = document.createElement('editor-element');
        const idPrefix = 'editor-element-footnote-mark';
        const elementId = generateId(editor, idPrefix);

        editorElement.setAttribute('type', 'footnote-mark');
        editorElement.setAttribute('id', elementId);
        editorElement.setAttribute('contentEditable', 'false');

        const textContainer = document.createElement('div');
        textContainer.className = 'text';
        textContainer.setAttribute('contentEditable', 'false');
        editorElement.appendChild(textContainer);

        return editorElement;
    }

    /**
     * @param page {HTMLElement}
     * @return {string[]}
     */
    getUniqueMarks(page) {
        const markSet = new Set();
        for (const editorElement of this.getElementsInContainer(page)) {
            const textContainer = editorElement.querySelector('.text');
            const mark = textContainer.innerText;
            markSet.add(mark);
        }
        return [...markSet];
    }

    /**
     * @param itemId {string}
     * @param page {HTMLElement}
     */
    getElementsByItemId(itemId, page) {
        return [...page.querySelectorAll(`[data-item-element="${itemId}"]`)];
    }

    /**
     * @param editor {EditorCustom}
     * @param data {undefined | {foreignLetters: boolean}}
     * @return {boolean}
     */
    insertElementAtCursor(editor, data = undefined) {
        const foreignLetters = data?.foreignLetters ?? false;
        let element = this.createEditorElement(editor);

        if (!elementCanBeInsertedAtSelection(editor, element)) {
            return false;
        }
        let selection = editor.selection.getContent();
        const tmpDiv = document.createElement('div');
        tmpDiv.innerHTML = selection;
        // this destroys formatting, but no time to waste right now
        selection = tmpDiv.textContent;

        /**
         * @type {undefined | string}
         */
        let footnote = undefined;
        /**
         * @type {undefined | string}
         */
        let foreignLetterAccent = undefined;

        if (foreignLetters) {
            let error = null;
            const words = selection
                .split(/\s+/g)
                .filter((word) => word.trim().length);
            if (words.length === 0) {
                // I18N
                error = 'Selecione uma palavra.';
            } else if (words.length > 1) {
                // I18N
                error = 'Selecione apenas uma palavra.';
            } else {
                const accentsRegexp = new RegExp(
                    `[${Object.values(accentsMap).join('')}]`,
                    'gi',
                );
                if (!words[0].match(accentsRegexp)) {
                    // I18N
                    error = 'A palavra selecionada não possui acento.';
                } else {
                    selection = words[0];
                }
            }

            if (error) {
                editor.notificationManager.open({
                    // I18N
                    text: error,
                    type: 'warning',
                    timeout: 5000,
                });
                return false;
            }
        }

        const elementId = element.getAttribute('id');

        editor.undoManager.transact(() => {
            if (foreignLetters) {
                const { text, accent } = separateAccent(selection);
                const brailleChar = foreignLetterBrailleMap[accent] ?? '?';
                selection =
                    text[0] + brailleChar + text[1] + text[2] + '&nbsp;';
                const accentBrName = accentsPtBrNameMap[accent] ?? '?';
                foreignLetterAccent = `${brailleChar}${text[1]}`;
                // I18N
                footnote = `${foreignLetterAccent} é igual ao ${text[1]} com ${accentBrName}.`;
                element.setAttribute(
                    'data-foreign-accent',
                    foreignLetterAccent,
                );
            }

            editor.selection.setContent(
                selection + element.outerHTML + '&nbsp;',
            );
            element = editor.dom.get(elementId);
        });

        this.getEditorElementFootnote().insertElementAtPage(
            getCurrentPage(this.editor),
        );

        const page = getClosestPage(element);

        const footnoteElement = getFootnoteElementFromPage(page);

        const textContainer = element.querySelector('.text');

        let footnoteItem = foreignLetters
            ? this.getEditorElementFootnoteItem().getElementByForeignLetterAccent(
                  page,
                  foreignLetterAccent,
              )
            : null;
        if (footnoteItem) {
            const footnoteItemMarkContainer =
                footnoteItem.querySelector('.mark');
            textContainer.innerText =
                footnoteItemMarkContainer.textContent.trimEnd();
        } else {
            const uniqueMarks = this.getUniqueMarks(page).length;
            textContainer.innerText =
                '(**' + (uniqueMarks === 1 ? '' : uniqueMarks.toString()) + ')';

            footnoteItem = this.getEditorElementFootnoteItem().addFootnoteItem(
                footnoteElement,
                textContainer.textContent,
                footnote,
                foreignLetterAccent,
            );
        }

        element.setAttribute(
            'data-item-element',
            footnoteItem.getAttribute('id'),
        );

        this.getEditorElementFootnoteItem().reorderElements(
            footnoteElement,
            page,
        );

        return true;
    }

    /**
     * @param element {HTMLElement}
     * @return {string}
     */
    convertToBraille(element) {
        const textContainer = element.querySelector('.text');
        return textContainer.textContent
            .trimEnd()
            .replaceAll('(', '`(')
            .replaceAll(')', '`)');
    }

    /**
     * @param container {HTMLElement}
     * @returns {HTMLElement[]}
     */
    getElementsInContainer(container) {
        return [
            ...container.querySelectorAll(
                'editor-element[type="footnote-mark"]',
            ),
        ];
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElement(element) {
        const textContainer = element.querySelector('.text');
        if (textContainer === null || textContainer.textContent.trim() === '') {
            element.remove();
            return;
        }
        const page = getClosestPage(element);
        const editorElements = this.getElementsInContainer(page);
        if (editorElements.length) {
            this.getEditorElementFootnote().insertElementAtPage(page);
        } else {
            this.getEditorElementFootnote().removeElementFromPage(page);
        }
        if (textContainer.textContent.endsWith(' ')) {
            textContainer.textContent = textContainer.textContent.trimEnd();
        }
    }

    /**
     * @param element {HTMLElement}
     * @returns {string[]}
     */
    getContextMenu(element) {
        this.lastElementSelectedContextMenu = element;
        return [
            'customContextMenuRemove',
            '|',
            'customEditorElementFootnoteMarkChangeModel',
        ];
    }
}
