/**
 * @enum {number}
 */
export const RevisionErrorEnum = {
    INVALID_CHAR: 0,
    INVALID_USE_OF_APOSTROPHE: 1,
    TEXT_MERGED_WITH_QUESTION: 2,
    ORDINAL_FOLLOWED_BY_TEMP_MEASURE: 3,
    DEGREE_NOT_FOLLOWED_BY_TEMP_MEASURE: 4,
    CURRENCY_FOLLOWED_BY_SPACE: 5,
    TEXT_MERGED_WITH_ALTERNATIVE: 6,
    MULTIPLES_SPACES_INSIDE_TEXT: 7,
    ORDINAL_NOT_FOLLOWED_BY_SPACE: 8,
    WORD_WITH_FOREIGN_CHAR: 9,
    IMAGE_NEEDS_REVIEW: 10,
};

/**
 * @param value {RevisionErrorEnum}
 * @return {string}
 */
export function RevisionErrorEnumToString(value) {
    switch (value) {
        default:
            throw new Error('Unsupported value');
        case RevisionErrorEnum.INVALID_CHAR:
            //I18N
            return 'Caractere não suportado pela plataforma.';
        case RevisionErrorEnum.INVALID_USE_OF_APOSTROPHE:
            //I18N
            return 'Uso incorreto de apóstrofo.';
        case RevisionErrorEnum.TEXT_MERGED_WITH_QUESTION:
            //I18N
            return 'Texto colado na questão.';
        case RevisionErrorEnum.ORDINAL_FOLLOWED_BY_TEMP_MEASURE:
            //I18N
            return 'Ordinal foi aplicado incorretamente.';
        case RevisionErrorEnum.DEGREE_NOT_FOLLOWED_BY_TEMP_MEASURE:
            //I18N
            return 'Grau foi aplicado incorretamente .';
        case RevisionErrorEnum.CURRENCY_FOLLOWED_BY_SPACE:
            //I18N
            return 'Símbolo de moeda seguido de espaço.';
        case RevisionErrorEnum.TEXT_MERGED_WITH_ALTERNATIVE:
            //I18N
            return 'Texto colado na alternativa.';
        case RevisionErrorEnum.MULTIPLES_SPACES_INSIDE_TEXT:
            //I18N
            return 'Múltiplos espaços dentro do texto.';
        case RevisionErrorEnum.ORDINAL_NOT_FOLLOWED_BY_SPACE:
            //I18N
            return 'Ordinal não seguido de espaço.';
        case RevisionErrorEnum.WORD_WITH_FOREIGN_CHAR:
            //I18N
            return 'Palavra com acento estrangeiro.';
        case RevisionErrorEnum.IMAGE_NEEDS_REVIEW:
            //I18N
            return 'Descrição de imagem precisa de revisão.';
    }
}
