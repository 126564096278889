import React from 'react';
import PropTypes from 'prop-types';
import './CoversTable.scss';
import DOMPurify from 'dompurify';

/**
 * @param records {CoversDto[] | null}
 * @param loadingRow {Element | null}
 * @param selected {number | null}
 * @param onSelected {function(CoversDto)}
 * @param showPreviewBraille {boolean | null}
 * @param onDoubleClick {function(CoversDto)}
 * @return {JSX.Element}
 * @constructor
 */

function CoversTable({
    records,
    loadingRow,
    selected,
    onSelected,
    onDoubleClick,
}) {
    return (
        <table className={'covers-table selectable'}>
            <thead>
                <tr>
                    <th>{'Descrição'}</th>
                </tr>
            </thead>
            <tbody>
                {records?.map((cover) => {
                    const classList = [];
                    if (selected === cover.id) {
                        classList.push('selected');
                    }

                    return (
                        <tr
                            key={cover.id}
                            onClick={() => {
                                onSelected(cover);
                            }}
                            className={classList.join(' ')}
                            onDoubleClick={() => {
                                onDoubleClick(cover);
                            }}
                        >
                            <td
                                className={'description'}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        cover.description,
                                    ),
                                }}
                            ></td>
                        </tr>
                    );
                })}

                {loadingRow && (
                    <tr>
                        <td colSpan={6}>{loadingRow}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

CoversTable.propTypes = {
    records: PropTypes.arrayOf(PropTypes.object),
    loadingRow: PropTypes.node,
    selected: PropTypes.number,
    onSelected: PropTypes.func.isRequired,
    showPreviewBraille: PropTypes.bool,
    onDoubleClick: PropTypes.func,
};

export default CoversTable;
