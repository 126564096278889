import FieldCheckbox from '../components/FieldCheckbox';
import FieldTextArea from '../components/FieldTextArea';
import './CoversModalForm.scss';
import PropTypes from 'prop-types';
import FieldText from '../components/FieldText';

/**
 * @param formData {FormData | null}
 * @param formError {FormError}
 * @param onSave {function()}
 * @param onRemove {function()}
 * @param onFormUpdate {function(FormData | null)}
 * @param onGeneratePreview {function()}
 * @param saveLoading {boolean}
 * @param removeLoading {boolean}
 * @returns {JSX.Element}
 * @constructor
 */
function CoversModalForm({
                             formData,
                             formError,
                             onSave,
                             onRemove,
                             onFormUpdate,
                             saveLoading,
                             removeLoading,
                         }) {
    const editing = !!formData;

    const clearForm = () => {
        onFormUpdate(null);
    };

    return (
        <form className={'condensed-controls'}>
            <label className={'field-label'}></label>
            <section className={'modal-form'}>
                <div className={'gd-inner'}>
                    <div className={'gd-col gd-col-7--desktop'}>
                        <FieldCheckbox
                            inputs={(() => [
                                {
                                    // I18N
                                    label: 'Novo modelo de página',
                                    checked: formData?.newCover ?? false,
                                    onChange: (e) => {
                                        const checked = e.target.checked;
                                        onFormUpdate({
                                            newCover: checked,
                                            description: '',
                                            cover: '',
                                            backCover: '',
                                        });
                                    },
                                },
                            ])()}
                        />
                    </div>
                </div>
                <div className={'gd-inner'}>
                    <div className={'gd-col gd-col-7--desktop'}>
                        <div className={'cover-container'}>
                            <FieldText
                                // I18N
                                label={'Descrição'}
                                required={true}
                                disabled={!editing}
                                validationError={formError.description}
                                value={formData?.description ?? ''}
                                onChange={(e) =>
                                    onFormUpdate({
                                        description: e.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className={'gd-inner'}>
                    <div className={'gd-col gd-col-7--desktop'}>
                        <div className={'cover-container'}>
                            <FieldTextArea
                                // I18N
                                label={'Capa'}
                                required={true}
                                wrap={'off'}
                                disabled={!editing}
                                validationError={formError.cover}
                                value={formData?.cover ?? ''}
                                rows={20}
                                onChange={(e) => {
                                    onFormUpdate({
                                        cover: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={'gd-inner'}>
                    <div className={'gd-col gd-col-7--desktop'}>
                        <div className={'cover-container'}>
                            <FieldTextArea
                                // I18N
                                label={'Folha de rosto'}
                                required={true}
                                wrap={'off'}
                                disabled={!editing}
                                validationError={formError.backCover}
                                value={formData?.backCover ?? ''}
                                rows={20}
                                onChange={(e) => {
                                    onFormUpdate({
                                        backCover: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={'gd-inner'}>
                    <div className={'gd-col gd-col-7--desktop'}>
                        <div className={'cover-container'}>
                            <FieldTextArea
                                // I18N
                                label={'Ficha técnica'}
                                required={true}
                                wrap={'off'}
                                disabled={!editing}
                                validationError={formError.technicalSheet}
                                value={formData?.technicalSheet ?? ''}
                                rows={20}
                                onChange={(e) => {
                                    onFormUpdate({
                                        technicalSheet: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>
            {editing && (
                <div className={'gd-col--align-between controls'}>
                    <div>
                        <button
                            className={'button'}
                            onClick={(e) => {
                                e.preventDefault();
                                clearForm();
                            }}
                        >
                            {'Cancelar'}
                        </button>
                        {formData && !formData.newCover && (
                            <button
                                className={`button ${removeLoading ? 'loading' : ''}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onRemove();
                                }}
                            >
                                {'Remover'}
                            </button>
                        )}
                        <button
                            className={`button primary ${saveLoading ? 'loading' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                onSave();
                            }}
                        >
                            {formData.newCover ? 'Cadastrar' : 'Salvar'}
                        </button>
                    </div>
                </div>
            )}
        </form>
    );
}

CoversModalForm.propTypes = {
    formData: PropTypes.shape({
        newCover: PropTypes.bool,
        description: PropTypes.string,
        cover: PropTypes.string,
        backCover: PropTypes.string,
        technicalSheet: PropTypes.string,
    }),
    formError: PropTypes.shape({
        description: PropTypes.string,
        cover: PropTypes.string,
        backCover: PropTypes.string,
        technicalSheet: PropTypes.string,
    }),
    onSave: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onFormUpdate: PropTypes.func.isRequired,
    saveLoading: PropTypes.bool,
    removeLoading: PropTypes.bool,
};

export default CoversModalForm;
