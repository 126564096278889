import { RoleEnum } from 'plataforma-braille-common';
import {
    getBrailleDocument,
    getClosestEditorElement,
    getClosestElementRepresentation,
    getClosestTextNode,
    isEditorElement,
    isEditorElementImageLayout,
    isEditorElementRepresentationParagraphBreak,
    isEditorPage,
    isMultipleSelection,
    isTinyCaret,
    preventScroll,
    scrollToCursorIfNeeded,
} from './core/EditorUtil';
import {
    fixPageTermination,
    getCurrentPage,
    getNextPage,
    getPages,
    goToPage,
    insertBlankPage,
    insertPageBreak,
    removeEmptyPage,
} from './core/PageManipulation';
import {
    getCaretLine,
    getCaretPosition,
    getLineCount,
    scanCaretPath,
    setCaretPosition,
} from './core/CaretPath';
import { isDebugEnabled } from './core/CoreModule';
import { uncachePage } from './core/Cache';
import { isEditorElementImage } from './core/editor-element/EditorElementImage';
import { isInsideEditorElementAngle } from './core/editor-element/EditorElementAngle';
import { isInsideEditorElementNthRoot } from './core/editor-element/EditorElementNthRoot';
import { isInsideEditorElementLineSegment } from './core/editor-element/EditorElementLineSegment';
import {
    isInsideAlignmentCenter,
    isInsideAlignmentRight,
} from './core/editor-element/EditorElementAlignment';
import { getElementOfPageNotFixed } from './ExcessLinesControlModule';

export const ZERO_WIDTH_NB_CHAR = '\uFEFF'; // special char to do some trick with caret position
export const ZERO_WIDTH_SPACE_CHAR = '\u200B';

export class KeyboardModule {
    /**
     * @type {HTMLElement | null}
     */
    keyDownCurrentPage = null;
    /**
     * @type {number | null}
     */
    keyDownLineCount = null;
    firePageDataChangedOnKeyUp = false;

    /**
     * @param editor {EditorCustom}
     * @param readOnly {boolean}
     * @param isAdmin {boolean}
     * @param isEvaluator {boolean}
     * @param isModerator {boolean}
     * @param userDocumentRoles {RoleEnum[]}
     */
    constructor(
        editor,
        readOnly,
        isAdmin,
        isEvaluator,
        isModerator,
        userDocumentRoles,
    ) {
        this.editor = editor;
        this.readOnly = readOnly;
        this.isAdmin = isAdmin;
        this.isEvaluator = isEvaluator;
        this.isModerator = isModerator;
        this.userDocumentRoles = userDocumentRoles;
    }

    debug(...data) {
        if (isDebugEnabled()) {
            console.debug('[KeyboardModule]', ...data);
        }
    }

    install() {
        const self = this;
        const userDescriptor =
            this.userDocumentRoles.includes(RoleEnum.DESCRIPTION) &&
            // users may have another roles in same document
            this.userDocumentRoles.length === 1;

        if (this.readOnly) {
            this.editor.on('keyDown', (e) => {
                // noinspection JSUnresolvedReference
                const preventDefault = e.key === 'Enter';
                if (preventDefault) {
                    // noinspection JSUnresolvedReference
                    e.preventDefault();
                }
            });
            this.editor.on('beforeExecCommand', (e) => {
                // noinspection JSUnresolvedReference
                e.preventDefault();
            });
        } else if (userDescriptor) {
            this.editor.on('keyDown', (e) => {
                // noinspection JSUnresolvedReference
                const preventDefault = e.key === 'Enter';
                if (preventDefault) {
                    // noinspection JSUnresolvedReference
                    e.preventDefault();
                    return;
                }
                this.keyDown(e);
            });
        } else {
            this.editor.on('keyDown', (e) => {
                this.keyDown(e);
            });
        }
        this.editor.on('keyUp', () => this.keyUp());
        this.editor.on('beforeInput', (e) => this.beforeInput(e));
        this.editor.on('input', () => {
            self.firePageDataChangedOnKeyUp = true;
        });
    }

    /**
     * @param e {KeyboardEvent}
     */
    enterPressed(e) {
        const self = this;
        function newPage() {
            self.debug('Blank page inserted');
            const newPage = insertBlankPage(self.editor);
            fixPageTermination(self.editor, newPage);
            self.editor.selection.setCursorLocation(newPage, 0);
            scrollToCursorIfNeeded(self.editor);
            self.firePageDataChangedOnKeyUp = true;
        }

        const brailleDocument = getBrailleDocument(this.editor);

        const selectedNode = this.editor.selection.getNode();
        if (e.shiftKey) {
            e.preventDefault();
            if (e.ctrlKey) {
                newPage();
            }
        } else if (
            //TODO: this should be reworked with EditorElements
            isInsideEditorElementNthRoot(selectedNode) ||
            isInsideEditorElementLineSegment(selectedNode) ||
            isInsideEditorElementAngle(selectedNode)
        ) {
            e.preventDefault();
            this.debug('Enter prevented in unsupported element');
        } else if (e.ctrlKey) {
            this.debug('Page break');
            e.preventDefault();
            const currentPage = getCurrentPage(this.editor);
            const newPage = insertPageBreak(this.editor);
            currentPage.setAttribute('data-needs-update', 'true');
            newPage.setAttribute('data-needs-update', 'true');
            scrollToCursorIfNeeded(this.editor);
            self.firePageDataChangedOnKeyUp = true;
        } else if (brailleDocument.convertedToBraille) {
            const { line: currentLine, page: currentPage } = getCaretLine(
                this.editor,
            );
            // in last line of page
            // add two count a not inserted yet enter
            if (currentLine + 1 >= brailleDocument.brailleCellRowCount) {
                if (!getNextPage(currentPage)) {
                    newPage();
                }
            }
            self.firePageDataChangedOnKeyUp = true;
        } else {
            self.firePageDataChangedOnKeyUp = true;
        }
    }

    getCaretPosition() {
        return this.editor.custom.coreModule.inputCaretPosition;
    }

    /**
     * @param e {KeyboardEvent}
     */
    arrowUpPressed(e) {
        const { line: currentLine, page: currentPage } = getCaretLine(
            this.editor,
        );
        if (!currentPage) return;
        if (currentLine === 0) {
            const previousPage = currentPage.previousSibling;
            if (previousPage) {
                e.preventDefault();
                uncachePage(this.editor, previousPage);
                const path = scanCaretPath(previousPage);
                path.pop(); // break line termination is undesired here
                setCaretPosition(this.editor, previousPage, path);
                scrollToCursorIfNeeded(this.editor);
                this.debug('Caret positioned in previous page');
            }
        }
        this.fixCaretPosition();
    }

    /**
     * @param e {KeyboardEvent}
     */
    arrowDownPressed(e) {
        const { line: currentLine, page: currentPage } = getCaretLine(
            this.editor,
        );
        if (!currentPage) return;
        const linesInPage = getLineCount(currentPage);
        // in last line of page
        if (
            currentLine >= linesInPage &&
            !getClosestEditorElement(this.editor.selection.getNode())
        ) {
            const nextPage = currentPage.nextSibling;
            if (nextPage) {
                e.preventDefault();
                uncachePage(this.editor, nextPage);

                let firstElement = getElementOfPageNotFixed(
                    this.editor.custom.coreModule.editorElements,
                    nextPage,
                    true,
                );

                if (firstElement?.nodeType !== Node.TEXT_NODE || firstElement) {
                    const textNode =
                        document.createTextNode(ZERO_WIDTH_NB_CHAR);
                    firstElement.before(textNode);
                    firstElement = textNode;
                }

                this.editor.selection.setCursorLocation(firstElement, 0);
                this.fixCaretPosition();
                preventScroll(this.editor);
                this.debug('Caret positioned in next page');
            }
        }
    }

    arrowLeftPressed() {}

    arrowRightPressed() {}

    /**
     * @param e {KeyboardEvent}
     */
    pageUp(e) {
        e.preventDefault();
        const pages = getPages(this.editor);
        const currentPage = getCurrentPage(this.editor);
        const goToIdx = pages.indexOf(currentPage) - 1;
        if (goToIdx < 0) {
            goToPage(this.editor, 0);
            setCaretPosition(this.editor, pages[0], []);
            return;
        }
        goToPage(this.editor, goToIdx);
        const toPage = pages[goToIdx];
        uncachePage(this.editor, toPage);
        setCaretPosition(this.editor, toPage, scanCaretPath(toPage));
    }

    /**
     * @param e {KeyboardEvent}
     */
    pageDown(e) {
        e.preventDefault();
        const pages = getPages(this.editor);
        const currentPage = getCurrentPage(this.editor);
        const goToIdx = pages.indexOf(currentPage) + 1;
        if (goToIdx >= pages.length) {
            goToPage(this.editor, pages.length - 1);
            setCaretPosition(
                this.editor,
                pages[pages.length - 1],
                scanCaretPath(pages[pages.length - 1]),
            );
            return;
        }
        goToPage(this.editor, goToIdx);
        const toPage = pages[goToIdx];
        uncachePage(this.editor, toPage);
        setCaretPosition(this.editor, toPage, []);
    }

    /**
     * @param e {KeyboardEvent}
     */
    keyDown(e) {
        if (isMultipleSelection(this.editor)) return;
        const currentPage = getCurrentPage(this.editor);
        if (currentPage) {
            this.keyDownCurrentPage = currentPage;
            this.keyDownLineCount = getLineCount(currentPage);
        }
        if (e.key !== 'Enter' && (e.shiftKey || e.altKey)) return;

        switch (e.key) {
            case 'Enter':
                this.enterPressed(e);
                break;
            case 'ArrowUp':
                this.arrowUpPressed(e);
                break;
            case 'ArrowDown':
                this.arrowDownPressed(e);
                break;
            case 'ArrowLeft':
                this.arrowLeftPressed(e);
                break;
            case 'ArrowRight':
                this.arrowRightPressed(e);
                break;
            case 'PageUp':
                this.pageUp(e);
                break;
            case 'PageDown':
                this.pageDown(e);
                break;
        }
    }

    /**
     * @param toRight {boolean | undefined}
     */
    fixCaretPosition(toRight = true) {
        if (this.editor.custom.isShowingNonPrintableChars) {
            let representation = getClosestElementRepresentation(
                this.editor.selection.getRng().endContainer,
            );
            if (representation) {
                /**
                 * @param element {Node}
                 */
                function isZeroWidthNbChar(element) {
                    return (
                        element &&
                        element.nodeType === Node.TEXT_NODE &&
                        element.textContent === ZERO_WIDTH_NB_CHAR
                    );
                }

                const isLineBreak =
                    isEditorElementRepresentationParagraphBreak(representation);
                const offset = isLineBreak ? 0 : 1;
                let insertLeft = !isLineBreak;
                if (toRight) insertLeft = !insertLeft;

                let textNode;
                if (insertLeft) {
                    if (isZeroWidthNbChar(representation.previousSibling)) {
                        textNode = representation.previousSibling;
                    } else {
                        textNode = document.createTextNode(ZERO_WIDTH_NB_CHAR);
                        representation.before(textNode);
                    }
                    this.editor.selection.setCursorLocation(textNode, offset);
                } else {
                    if (isZeroWidthNbChar(representation.nextSibling)) {
                        textNode = representation.nextSibling;
                    } else {
                        textNode = document.createTextNode(ZERO_WIDTH_NB_CHAR);
                        representation.after(textNode);
                    }
                    this.editor.selection.setCursorLocation(textNode, offset);
                }
            }
        }
        // this happens when you have a <editor-element><br><editor-element> (image/summary) and removes the <br> between
        if (isTinyCaret(this.editor.selection.getNode())) {
            const caretPosition = this.getCaretPosition();
            setCaretPosition(
                this.editor,
                caretPosition.contextElement,
                caretPosition.path,
            );
        }
    }

    firePageDataChanged() {
        const caretPosition = getCaretPosition(this.editor);
        if (!caretPosition) return;
        /**
         * @type {PageDataChangedEvent}
         */
        const pageDataChangedEvent = {
            caretPosition,
        };
        this.editor.fire('pageDataChanged', pageDataChangedEvent);
    }

    keyUp() {
        if (this.firePageDataChangedOnKeyUp) {
            try {
                this.firePageDataChanged();
            } finally {
                this.firePageDataChangedOnKeyUp = false;
            }
        }
    }

    /**
     * @param e {InputEvent}
     */
    beforeInput(e) {
        const selectedNode = this.editor.selection.getNode();

        switch (e?.inputType) {
            case 'insertLineBreak': {
                if (
                    isInsideEditorElementNthRoot(selectedNode) ||
                    isInsideEditorElementLineSegment(selectedNode) ||
                    isInsideEditorElementAngle(selectedNode)
                ) {
                    e.preventDefault();
                    this.debug('Enter prevented in unsupported element');
                }
                break;
            }
            case 'deleteContentForward': {
                this.deleteContentForward(e);
                break;
            }
            case 'deleteContentBackward': {
                this.deleteContentBackward(e);
                break;
            }
        }
    }

    /**
     * @param e {InputEvent}
     */
    deleteContentForward(e) {
        const caretPosition = this.getCaretPosition();
        if (!caretPosition) return;
        const contextPath = scanCaretPath(caretPosition.contextElement);

        if (removeEmptyPage(this.editor, caretPosition.contextElement)) {
            e.preventDefault();
            return;
        }

        const pagePath = scanCaretPath(caretPosition.contextElement);
        const lastPos = pagePath.length - 1 === caretPosition.path.length;

        // page always have a break line at end
        if (
            isEditorPage(caretPosition.contextElement) &&
            contextPath.length - 1 === caretPosition.path.length
        ) {
            const nextPage = caretPosition.contextElement.nextSibling;
            if (nextPage && lastPos) {
                e.preventDefault();
                setCaretPosition(
                    this.editor,
                    caretPosition.page,
                    caretPosition.path,
                );
                return;
            } else {
                // bug when last char of page are removed delete all page
                e.preventDefault();
                this.debug('Delete cancelled to remove page element');
                return;
            }
        }
        if (pagePath[caretPosition.path.length] === '\n') {
            e.preventDefault();
            let br;
            if (caretPosition.path.length) {
                br =
                    caretPosition.nodePath[caretPosition.nodePath.length - 1]
                        ?.nextElementSibling;
            } else {
                br = caretPosition.contextElement.firstElementChild;
            }
            if (isEditorElementRepresentationParagraphBreak(br)) {
                const representation = br;
                br = br.nextElementSibling;
                representation.remove();
            }
            if (br) {
                br.remove();
                this.debug('Next paragraph break removed');
            }
            this.firePageDataChanged();
        }
    }

    /**
     * @param e {InputEvent}
     */
    deleteContentBackward(e) {
        const caretPosition = this.getCaretPosition();
        if (!caretPosition) return;

        const editor = this.editor;
        const rng = editor.selection.getRng();
        const { startContainer, startOffset } = rng;

        // noinspection JSUnresolvedReference
        const isPriorEditorElement =
            isEditorElement(startContainer.previousSibling) ||
            (startContainer.previousSibling?.tagName === 'BR' &&
                isEditorElement(
                    startContainer.previousSibling?.previousSibling,
                ));

        if (isPriorEditorElement) {
            if (startOffset === 1 && startContainer.textContent.length) {
                // ticket #63330
                // fix a bug where the first character is not removed when there is a preceding editor element
                e.preventDefault();
                startContainer.textContent =
                    startContainer.textContent.substring(1);
                setTimeout(() => {
                    editor.selection.setCursorLocation(startContainer, 0);
                }, 0);
                this.firePageDataChanged();
            }
            return;
        }

        const isPage = isEditorPage(caretPosition.contextElement);
        if (
            isPage &&
            removeEmptyPage(this.editor, caretPosition.contextElement)
        ) {
            e.preventDefault();
            this.debug('Empty page removed');
            return;
        } else if (isTinyCaret(caretPosition.contextElement)) {
            e.preventDefault();
            return;
        }
        if (!isPage) return;
        if (caretPosition.path.length === 0) {
            const previousPage = caretPosition.contextElement.previousSibling;
            if (!previousPage) {
                e.preventDefault();
                return;
            }
            setCaretPosition(
                this.editor,
                previousPage,
                scanCaretPath(previousPage),
            );
        } else if (caretPosition.path.length === 1) {
            // this fix a bug removing first char of a page
            e.preventDefault();
            this.editor.undoManager.transact(() => {
                let { endContainer, endOffset } =
                    this.editor.selection.getRng();
                if (endContainer.nodeType !== Node.TEXT_NODE) {
                    endContainer = endContainer.childNodes[endOffset];
                    endOffset = 0;
                }
                if (
                    endOffset > 0 &&
                    endContainer.textContent !== ZERO_WIDTH_NB_CHAR
                ) {
                    endContainer.textContent =
                        endContainer.textContent.substring(1);
                } else {
                    endContainer.previousSibling?.remove();
                }
                setCaretPosition(this.editor, caretPosition.contextElement, []);
                this.firePageDataChanged();
            });
        } else if (caretPosition.path[caretPosition.path.length - 1] === '\n') {
            e.preventDefault();
            const br = caretPosition.nodePath[caretPosition.path.length - 1];
            if (br.style?.display !== 'none') {
                if (
                    isEditorElementRepresentationParagraphBreak(
                        br.previousElementSibling,
                    )
                ) {
                    const representation = br.previousElementSibling;
                    representation.remove();
                }
                br.remove();
                this.debug('Previous paragraph break removed');
                const caretPosition = this.getCaretPosition();
                caretPosition.path.pop();
                this.firePageDataChanged();
            } else {
                const previous = br.previousElementSibling;
                // exceptions
                if (
                    isEditorElementImage(previous) ||
                    isEditorElementImageLayout(previous)
                ) {
                    const pageNumber = previous.querySelector('.page-number');
                    const text = getClosestTextNode(pageNumber.lastChild);
                    if (text) {
                        this.editor.selection.setCursorLocation(
                            text,
                            text.textContent.length,
                        );
                        this.debug('Element focused');
                    }
                } else if (
                    isInsideAlignmentCenter(previous) ||
                    isInsideAlignmentRight(previous)
                ) {
                    const text = getClosestTextNode(previous.lastChild);
                    if (text) {
                        this.editor.selection.setCursorLocation(
                            text,
                            text.textContent.length,
                        );
                        this.debug('Alignment element focused');
                    }
                }
            }
        }
    }
}
