import Api from './Api';

/**
 * @typedef {object} CoversDto
 * @property {number} id
 * @property {string} description
 * @property {string} cover
 * @property {string} backCover
 * @property {string} technicalSheet
 */

/**
 * @param filter { { page: number | null | undefined, search: string | null} }
 * @param config {import('axios').AxiosRequestConfig | null | undefined}
 * @returns {Promise<PaginationResDto<CoversDto>>}
 */
export async function getCovers(filter, config = null) {
    return (
        await Api.get('/covers', {
            params: filter,
            ...(config ?? {}),
        })
    )?.data;
}

/**
 * @param formData {FormData}
 * @returns {Promise<CoversDto>}
 */
export async function createCover(formData) {
    return (await Api.put('/covers', formData)).data;
}

/**
 * @param id {number}
 * @param formData {FormData}
 * @returns {Promise<CoversDto>}
 */
export async function editCover(id, formData) {
    return (await Api.patch(`/covers/${id}`, formData)).data;
}

/**
 * @param id {number}
 * @returns {Promise<import('axios').AxiosResponse<any>>}
 */
export async function deleteCover(id) {
    return await Api.delete(`/covers/${id}}`);
}
