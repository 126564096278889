import CloseModalButton from '../components/modal/CloseModalButton';
import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CoversModalList from '../covers/CoversModalList';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import {
    getCoverPage,
    insertCoverPage,
    removeCoverPage,
} from './editor-mods/modules/core/PageManipulation';

/**
 * @param show {boolean}
 * @param onClose {function}
 * @param editor {EditorCustom}
 * @returns {JSX.Element}
 * @constructor
 */
function CoversModal({ show, onClose, editor }) {
    const { setConfirmModal } = useContext(EnvironmentContext);

    /**
     * @type {React.MutableRefObject<CoversModalListFunctions | null>}
     */
    const coversModalListRef = useRef(null);
    /**
     * @type {CoversDto | null}
     */
    const [selectedCover, setSelectedCover] = useState(null);

    if (!editor) {
        return <></>;
    }

    /**
     * @returns {boolean}
     */
    function insertCover() {
        if (getCoverPage(editor)) {
            setConfirmModal({
                title: 'Substituir os modelos de página existentes?',
                message:
                    'Se prosseguir, os modelos de página serão substituídos pelo modelo selecionado.',
                confirmText: 'Confirmar substituição',
                onConfirm: () => {
                    removeCoverPage(editor);
                    insertCoverPage(editor, selectedCover);
                    onClose();
                },
                show: true,
            });
        } else {
            insertCoverPage(editor, selectedCover);
            onClose();
        }
    }

    const classList = ['modal', 'default-modal', 'cover-modal'];
    if (show) {
        classList.push('show');
    }

    return (
        <div className={classList.join(' ')}>
            <div className={'backdrop'} />
            <div className={'container'}>
                <CloseModalButton onClick={onClose} />
                {/*I18N*/}
                <h2>{'Modelos de página'}</h2>
                <div className={'gd-inner'}>
                    <div className={'gd-col gd-col-8--desktop'}>
                        <div className={'list-container'}>
                            <CoversModalList
                                ref={coversModalListRef}
                                selected={selectedCover?.id}
                                showPreviewBraille={true}
                                onSelected={(selected) => {
                                    setSelectedCover(selected);
                                }}
                                showingModal={show}
                                showLabel={false}
                                onDoubleClick={() => {
                                    if (insertCover()) {
                                        onClose();
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                {selectedCover && (
                    <div className={'controls'}>
                        <div>
                            <button
                                className={'button primary'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (insertCover()) {
                                        onClose();
                                    }
                                }}
                            >
                                {/*I18N*/}
                                {'Inserir modelo de página'}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

CoversModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    editor: PropTypes.object
};

export default CoversModal;
