import { Editor } from '@tinymce/tinymce-react';
import {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { getVersion } from '../version';
import * as Scroll from './editor-mods/modules/ScrollModule';
import { ScrollModule, updateScroll } from './editor-mods/modules/ScrollModule';
import PropTypes from 'prop-types';
import { EnvironmentContext } from '../contexts/EnviromentContext';
import {
    DocumentStatusEnumToString,
    getDocumentStatusUserRoleEnum,
} from 'plataforma-braille-common';
import {
    getBrailleDocument,
    getClosestEditorElement,
    getHtmlToSave,
    isEditorElementParagraphBreak,
    preventScroll,
    replaceHtmlEdgeSpacesWithNbsp,
    standardizeChar,
} from './editor-mods/modules/core/EditorUtil';
import { MouseModule } from './editor-mods/modules/MouseModule';
import * as InsertSymbolsAndSpecialCharacters from './editor-mods/modules/SymbolsAndSpecialCharacters';
import {
    getCaretPosition,
    scanCaretPath,
    setCaretPosition,
} from './editor-mods/modules/core/CaretPath';
import { getUserRolesFromDocument } from './UserPermissions';
import {
    removeNonPrintableChars,
    toggleNonPrintableChars,
} from './editor-mods/modules/core/ShowNonPrintableChars';
import {
    getCurrentPage,
    getPages,
    goToPage,
    insertBlankPage,
} from './editor-mods/modules/core/PageManipulation';
import {
    getTinyMceMenu,
    getTinyMceMenuBar,
    getTinyMceToolbar,
} from './editor-mods/modules/menu/TinyMceToolbarAndMenus';
import { installMenuModule } from './editor-mods/modules/menu/MenuModule';
import { KeyboardModule } from './editor-mods/modules/KeyboardModule';
import { CoreModule } from './editor-mods/modules/core/CoreModule';
import {
    cacheContent,
    getPageCache,
    uncacheContent,
    uncachePage,
} from './editor-mods/modules/core/Cache';
import { sanitizeHtml } from './editor-mods/modules/core/SanitizeHtml';
import { syncDictionaryUnbreakable } from './DictionaryUnbreakable';
import { ExcessLinesControlModule } from './editor-mods/modules/ExcessLinesControlModule';
import { getEditorElementLinesCount } from './editor-mods/modules/core/EditorElements';

/**
 * @return {string}
 */
function getNavigationPositionKey() {
    return `documentNavigationPosition:${window.location.pathname}`;
}

/**
 * @typedef {object} RichEditorParams
 * @property {BrailleDocument} doc
 * @property {function()} onImportPdf
 * @property {function()} onEditDocument
 * @property {function()} onRemoveDocument
 * @property {function()} onRename
 * @property {function()} onHyphenationSettings
 * @property {function()} onDocumentSettings
 * @property {function()} onInitialized
 * @property {function()} onInput
 * @property {function()} onExportBrailleFacil
 * @property {function()} onExportPdf
 * @property {function()} onExportDocx
 * @property {function()} onUserWithAccess
 * @property {function()} onChangeDocumentStatus
 * @property {function()} onForceUnlockDocument
 * @property {function()} onUndoManagerAdd
 * @property {function()} onRetrieveImagesDescriptionsBackground
 * @property {function()} onDictionary
 * @property {function()} onAddFigure
 * @property {function()} onAddCover
 * @property {function()} onIdentification
 * @property {function()} onIdentificationSuppressInAllPagesAction
 * @property {function()} onIdentificationExposeInAllPagesAction
 * @property {function()} onPagination
 */

/**
 * @typedef {object} RichEditorFunctions
 * @property {function():Promise<string>} getHtml
 * @property {function(string):Promise<void>} setHtml
 * @property {function():EditorCustom} getEditor
 * @property {function():DOMUtils} getDom
 * @property {function():NotificationManager} getNotificationManager
 * @property {function():EditorSelection} getSelection
 * @property {function():void} focus
 * @property {function(string):void} setDocumentTitle
 * @property {function(any):void} setProgressState
 * @property {function():Promise<void>} updatePages
 * @property {function():void} forceUpdateDoc
 * @property {function(UserWithAccessDto[] | undefined):void} updateDocumentDesignatedUsers
 */

/**
 * @type {React.ForwardRefExoticComponent<RichEditorParams>}
 */
export const RichEditor = forwardRef(
    (
        {
            doc,
            onImportPdf,
            onEditDocument,
            onRemoveDocument,
            onRename,
            onHyphenationSettings,
            onDocumentSettings,
            onInitialized,
            onInput,
            onExportBrailleFacil,
            onExportPdf,
            onExportDocx,
            onUserWithAccess,
            onChangeDocumentStatus,
            onForceUnlockDocument,
            onUndoManagerAdd,
            onRetrieveImagesDescriptionsBackground,
            onDictionary,
            onAddFigure,
            onAddCover,
            onIdentification,
            onIdentificationSuppressInAllPagesAction,
            onIdentificationExposeInAllPagesAction,
            onPagination,
        },
        ref,
    ) => {
        /**
         * @type {EditorCustom | null}
         */
        const editorInitialValue = null;
        const [editor, setEditor] = useState(editorInitialValue);
        const {
            setLoading,
            isAdmin,
            isEvaluator,
            isModerator,
            isPrintShop,
            user,
        } = useContext(EnvironmentContext);
        const [key, setKey] = useState(Math.random());
        const navigate = useNavigate();
        const userDocumentRoles = getUserRolesFromDocument(user, doc);
        const documentTitleRef = useRef(null);
        const navigationPositionLock = useRef(true);
        /**
         * @type {React.MutableRefObject<HTMLInputElement | null>}
         */
        const zoomInputRef = useRef(null);
        /**
         * @type {React.MutableRefObject<HTMLDivElement | null>}
         */
        const customStatusBarContainerRef = useRef(null);
        /**
         * @type {React.MutableRefObject<HTMLDivElement | null>}
         */
        const documentStatusRef = useRef(null);
        /**
         * @type {React.MutableRefObject<HTMLDivElement | null>}
         */
        const documentScoreRef = useRef(null);
        /**
         * @type {React.MutableRefObject<HTMLDivElement | null>}
         */
        const statusCaretNavigationRef = useRef(null);
        /**
         * @type {React.MutableRefObject<HTMLDivElement | null>}
         */
        const statusCaretNavigationPgRef = useRef(null);
        /**
         * @type {React.MutableRefObject<HTMLDivElement | null>}
         */
        const statusCaretNavigationPgCountRef = useRef(null);
        /**
         * @type {React.MutableRefObject<HTMLDivElement | null>}
         */
        const statusCaretNavigationLnRef = useRef(null);
        /**
         * @type {React.MutableRefObject<HTMLDivElement | null>}
         */
        const statusCaretNavigationColRef = useRef(null);
        /**
         * @type {React.MutableRefObject<HTMLDivElement | null>}
         */
        const designatedUsersStatusRef = useRef(null);
        /**
         * @type {React.MutableRefObject<null | HTMLElement>}
         */
        const lastElementFocused = useRef(null);

        const initializedRef = useRef(false);

        /**
         * @type {number | null}
         */
        let tUpdateStatusCaretNavigation;

        /**
         * @param editor {EditorCustom}
         * @param fastUpdate {boolean | undefined}
         */
        function updateStatusCaretNavigation(editor, fastUpdate = false) {
            clearTimeout(tUpdateStatusCaretNavigation);
            tUpdateStatusCaretNavigation = setTimeout(
                () => {
                    if (!statusCaretNavigationRef.current) {
                        return;
                    }
                    const caretPosition = getCaretPosition(editor, true);
                    const pages = getPages(editor);
                    statusCaretNavigationPgCountRef.current.innerText =
                        pages.length.toString();
                    if (!caretPosition) {
                        statusCaretNavigationPgRef.current.innerText = '-';
                        statusCaretNavigationLnRef.current.innerText = '-';
                        statusCaretNavigationColRef.current.innerText = '-';
                        const activeElement = editor.getDoc()?.activeElement;
                        // noinspection JSUnresolvedReference
                        if (activeElement?.blur) {
                            // noinspection JSUnresolvedReference
                            activeElement?.blur();
                        }
                        return;
                    }
                    statusCaretNavigationRef.current.style.removeProperty(
                        'display',
                    );
                    const { path, page } = caretPosition;
                    let ln = 1;
                    let col = 1;
                    let lnOffset;
                    for (const pathEle of path) {
                        col++;
                        if (
                            pathEle === '\n' ||
                            isEditorElementParagraphBreak(pathEle)
                        ) {
                            ln++;
                            col = 1;
                        } else if (
                            (lnOffset = getEditorElementLinesCount(pathEle))
                        ) {
                            ln += lnOffset - 1;
                        }
                    }
                    let pg = pages.indexOf(page) + 1;
                    statusCaretNavigationPgRef.current.innerText =
                        pg.toString();
                    statusCaretNavigationLnRef.current.innerText =
                        ln.toString();
                    statusCaretNavigationColRef.current.innerText =
                        col.toString();
                    tUpdateStatusCaretNavigation = null;
                },
                fastUpdate ? 50 : 300,
            );
        }

        /**
         * Sets the title of the document.
         *
         * @param {string} title - The title to be set.
         *
         * @return {undefined}
         */
        function setDocumentTitle(title) {
            if (documentTitleRef.current) {
                documentTitleRef.current.innerText = title;
                documentTitleRef.current.title = title;
            }
        }

        /**
         * @typedef {object} NavigationPosition
         * @property {number} scrollTop
         * @property {number} scrollLeft
         * @property {number | null} page
         * @property {number | null} caretPosition
         */

        /**
         * @param editor {EditorCustom}
         */
        function storeNavigationPosition(editor) {
            if (navigationPositionLock.current) {
                return;
            }
            const caretPosition = getCaretPosition(editor);
            /**
             * @type {NavigationPosition}
             */

            const navigationPosition = {
                scrollTop: editor.getBody().parentElement.scrollTop,
                scrollLeft: editor.getBody().parentElement.scrollLeft,
                page: caretPosition
                    ? getPages(editor).indexOf(caretPosition.page)
                    : null,
                caretPosition: caretPosition ? caretPosition.path.length : null,
            };
            const json = JSON.stringify(navigationPosition);
            localStorage?.setItem(getNavigationPositionKey(), json);
        }

        function restoreNavigationPosition() {
            navigationPositionLock.current = false;
            const json = localStorage.getItem(getNavigationPositionKey());
            if (json) {
                /**
                 * @type {NavigationPosition}
                 */
                const navigationPosition = JSON.parse(json);
                if (navigationPosition?.scrollTop) {
                    editor.getBody().parentElement.scrollTo({
                        behavior: 'instant',
                        top: navigationPosition.scrollTop,
                        left: navigationPosition.scrollLeft,
                    });
                }
                if (
                    navigationPosition?.page &&
                    navigationPosition?.page !== -1 &&
                    navigationPosition.caretPosition
                ) {
                    const page = getPages(editor)[navigationPosition.page];
                    uncachePage(editor, page);
                    const pagePath = scanCaretPath(page);
                    const idx = navigationPosition.caretPosition;
                    pagePath.splice(idx, pagePath.length - idx);
                    setCaretPosition(editor, page, pagePath);
                }
            } else {
                setCaretPosition(editor, getPages(editor)[0], []);
            }
        }

        useImperativeHandle(ref, () => ({
            getHtml: async () => {
                return getHtmlToSave(editor);
            },
            /**
             * @return {EditorCustom | null}
             */
            getEditor: () => editor,
            getDom: () => editor.dom,
            getNotificationManager: () => editor.notificationManager,
            getSelection: () => editor.selection,
            focus: () => editor.focus(),
            setDocumentTitle,
            setProgressState: (...params) => editor.setProgressState(params),
            updatePages,
            setHtml,
            forceUpdateDoc: () => {
                initializedRef.current = false;
                zoomInputRef.current = null;
                documentStatusRef.current = null;
                documentTitleRef.current = null;
                customStatusBarContainerRef.current = null;
                statusCaretNavigationColRef.current = null;
                statusCaretNavigationLnRef.current = null;
                statusCaretNavigationPgRef.current = null;
                statusCaretNavigationPgCountRef.current = null;
                statusCaretNavigationRef.current = null;
                documentStatusRef.current = null;
                designatedUsersStatusRef.current = null;
                documentScoreRef.current = null;
                let random;
                do {
                    random = Math.random();
                } while (random === key);
                setKey(random);
            },
            updateDocumentDesignatedUsers,
        }));

        function exit() {
            navigate('/');
        }

        async function initializeDoc(event) {
            if (event && event['set'] !== true) {
                return;
            }

            function restoreZoomAndNavigationPosition() {
                restoreNavigationPosition();
            }

            editor.off('SetContent', initializeDoc);
            setTimeout(restoreZoomAndNavigationPosition, 0);
            let somePageUpdated = false;

            // should sync before prepare document
            if (getBrailleDocument(editor).convertedToBraille) {
                await syncDictionaryUnbreakable(setLoading);
            }

            await editor.custom.coreModule.updatePages(
                false,
                (currentPage, pageCount, pageUpdated) => {
                    const loading = currentPage < pageCount;
                    if (pageUpdated) somePageUpdated = true;
                    if (somePageUpdated) {
                        setLoading(
                            loading,
                            false,
                            // I18N
                            'Preparando documento...',
                            (currentPage / pageCount) * 100,
                        );
                    }
                },
            );
            updateStatusCaretNavigation(editor);
            if (somePageUpdated) {
                setTimeout(restoreZoomAndNavigationPosition, 0);
            }
            editor.undoManager.reset();
            console.debug('Document is ready.');
            editor.fire('documentReady');
        }

        async function setHtml(html) {
            editor.setContent(standardizeChar(html));
            await initializeDoc(null);
        }

        /**
         * @return {Promise<void>}
         */
        async function updatePages() {
            const loadingMessage = 'Atualizando páginas...';
            await editor.custom.coreModule.updatePages(
                true,
                (currentPage, pageCount) => {
                    // I18N
                    setLoading(
                        currentPage < pageCount,
                        false,
                        loadingMessage,
                        (currentPage / pageCount) * 100,
                    );
                },
            );
            updateScroll(editor);
        }

        // function navigateFavorites() {
        //     // I18N
        //     navigate('/favoritos');
        // }

        /**
         * WARNING: this code may break in different versions of TinyMCE
         * @param editor {EditorCustom}
         * @param buttonsTitles {string[]}
         */
        function hackMoveButtonsToMenu(editor, buttonsTitles) {
            const menuBar = document.querySelector('[role="menubar"]');
            if (!menuBar) {
                console.error('Cannot find menu bar');
                return;
            }
            const firstChild = menuBar.firstChild;
            for (const buttonTitle of buttonsTitles) {
                /**
                 * @type {HTMLElement}
                 */
                const button = document.querySelector(
                    `button[title="${buttonTitle}"]`,
                );
                if (!button) {
                    console.error(
                        `Cannot move button to menu: \`button[title="${buttonTitle}"]\``,
                    );
                    continue;
                }
                button.classList.add('hack-menubar-button');
                menuBar.insertBefore(button, firstChild);
            }
        }

        function hackCreateDocumentTitle() {
            if (documentTitleRef.current) return;
            /**
             * @type {HTMLElement}
             */
            const menuBar = document.querySelector('[role="menubar"]');
            if (!menuBar) {
                console.error('Cannot find TinyMce menu bar');
                return;
            }
            const documentTitleContainer = document.createElement('div');
            documentTitleContainer.className = 'document-title';
            menuBar.appendChild(documentTitleContainer);
            documentTitleRef.current = document.createElement('span');
            documentTitleContainer.appendChild(documentTitleRef.current);
            setDocumentTitle(doc?.name);
        }

        /**
         * @return {HTMLElement}
         */
        function getStatusBar() {
            return document.querySelector('.tox-statusbar');
        }

        function hackCreateCustomStatusBar() {
            if (customStatusBarContainerRef.current) return;
            const statusBar = getStatusBar();
            if (!statusBar) {
                console.error('Cannot find TinyMce status bar.');
                return;
            }
            const customStatusBarContainer = document.createElement('div');
            customStatusBarContainer.className = 'custom-status-bar-container';
            customStatusBarContainerRef.current = customStatusBarContainer;
            statusBar.appendChild(customStatusBarContainer);
            editor.custom.customStatusBarContainer = customStatusBarContainer;
        }

        /**
         * @param editor {EditorCustom}
         */
        function createStatusCaretNavigation(editor) {
            if (statusCaretNavigationRef.current) return;
            const container = document.createElement('div');
            container.classList.add('interactive');
            statusCaretNavigationRef.current = container;
            customStatusBarContainerRef.current.appendChild(
                statusCaretNavigationRef.current,
            );
            container.appendChild(document.createTextNode('Pg '));
            /**
             * @type {HTMLElement}
             */
            const pg = document.createElement('strong');
            pg.setAttribute('contentEditable', 'true');
            statusCaretNavigationPgRef.current = pg;
            container.append(pg);
            pg.addEventListener('beforeinput', (e) => {
                if (
                    (e?.data && (e.data < '0' || e.data > '9')) ||
                    e?.inputType === 'insertParagraph'
                ) {
                    e.preventDefault();
                }
            });
            pg.addEventListener('keydown', (e) => {
                if (e?.key === 'Enter') {
                    let page = parseInt(pg.textContent.trim());
                    if (!isNaN(page)) {
                        page = goToPage(editor, page - 1);
                        pg.innerText = (page + 1).toString();
                        pg?.blur();
                    }
                }
            });

            container.appendChild(document.createTextNode('/'));
            const pgCount = document.createElement('strong');
            statusCaretNavigationPgCountRef.current = pgCount;
            container.appendChild(pgCount);

            container.appendChild(document.createTextNode(', Ln '));
            const ln = document.createElement('strong');
            statusCaretNavigationLnRef.current = ln;
            container.appendChild(ln);

            container.appendChild(document.createTextNode(', Col '));
            const col = document.createElement('strong');
            statusCaretNavigationColRef.current = col;
            container.appendChild(col);
        }

        function createDocumentStatus() {
            if (documentStatusRef.current) return;
            const documentStatus = document.createElement('div');
            documentStatus.className = 'document-status';
            documentStatusRef.current = documentStatus;
            customStatusBarContainerRef.current.appendChild(documentStatus);
        }
        function createDesignatedUsersStatus() {
            if (designatedUsersStatusRef.current) return;
            const designedUsers = document.createElement('div');
            designatedUsersStatusRef.current = designedUsers;
            designedUsers.className = 'designated-users-status';
            customStatusBarContainerRef.current.appendChild(designedUsers);
        }

        function createDocumentScore() {
            if (documentScoreRef.current) return;
            const documentScore = document.createElement('div');
            documentScore.className = 'document-score';
            documentScoreRef.current = documentScore;
            customStatusBarContainerRef.current.appendChild(documentScore);
        }

        function updateDocumentStatus() {
            if (!documentStatusRef.current) return;
            documentStatusRef.current.innerText = doc.status
                ? DocumentStatusEnumToString(doc.status)?.toLowerCase()
                : // I18N
                  'indisponível';
            // I18N
            documentStatusRef.current.title = `Status: ${documentStatusRef.current.innerText}`;
        }

        /**
         * @param userWithAccess {UserWithAccessDto[] | undefined}
         */
        function updateDocumentDesignatedUsers(userWithAccess = null) {
            if (!designatedUsersStatusRef.current) return;
            const userRole = getDocumentStatusUserRoleEnum(doc.status);
            if (!userWithAccess) {
                userWithAccess = doc.userWithAccess ?? [];
            }
            const users = userWithAccess.filter((user) =>
                user.roles.includes(userRole),
            );

            designatedUsersStatusRef.current.innerText = users
                .map((user) => user.name.trim().split(' ')[0])
                .join(', ');
            // I18N
            designatedUsersStatusRef.current.title = `Usuários designados: ${users.map((user) => user.name.trim()).join(', ')}`;
            if (!users.length) {
                designatedUsersStatusRef.current.style.display = 'none';
            } else {
                designatedUsersStatusRef.current.style.removeProperty(
                    'display',
                );
            }
        }

        function updateDocumentScore() {
            let hasScoreCount = 0;
            let totalScore = 0;

            for (const page of getPages(editor)) {
                let container = getPageCache(editor, page, false);
                if (!container) {
                    container = page;
                }

                /**
                 * @type {HTMLElement[]}
                 */
                const images = [
                    ...container.querySelectorAll(
                        'editor-element[type="image"]',
                    ),
                ];
                for (const image of images) {
                    let score = parseFloat(
                        image.getAttribute('data-score-value'),
                    );
                    if (isNaN(score)) {
                        continue;
                    }
                    hasScoreCount++;
                    const revised =
                        image.getAttribute('data-score-revised') != null;
                    if (revised) {
                        score = 1;
                    }
                    totalScore += score;
                }
            }

            totalScore = (totalScore / hasScoreCount) * 10;

            if (hasScoreCount) {
                documentScoreRef.current.innerHTML = `Score: <strong>${totalScore.toLocaleString(
                    undefined,
                    {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1,
                    },
                )}</strong>`;
                documentScoreRef.current.title =
                    documentScoreRef.current.innerText;
                documentScoreRef.current.style.removeProperty('display');
            } else {
                documentScoreRef.current.style.display = 'none';
            }
        }

        useEffect(() => {
            if (editor) {
                const observer = new MutationObserver(function (
                    mutationsList,
                    observer,
                ) {
                    const menuBar = document.querySelector('[role="menubar"]');
                    if (menuBar && menuBar.childNodes.length) {
                        observer.disconnect();

                        hackMoveButtonsToMenu(editor, ['Home']);
                        hackCreateDocumentTitle();
                        hackCreateCustomStatusBar();

                        createStatusCaretNavigation(editor);
                        createDocumentStatus();
                        createDocumentScore();
                        createDesignatedUsersStatus();
                        editor.setContent(doc.html, { format: 'raw' });

                        console.debug('Editor initialized.');
                        if (onInitialized && !initializedRef.current) {
                            initializedRef.current = true;
                            onInitialized(editor);
                        }

                        updateDocumentStatus();
                        updateDocumentDesignatedUsers();
                        updateDocumentScore();
                    }
                });
                editor.custom.updateDocumentScore = updateDocumentScore;

                observer.observe(document.body, {
                    attributes: false,
                    childList: true,
                    subtree: true,
                });

                const originalUnderManagerAdd = editor.undoManager.add;
                // this is a hack and may stop to work in newer versions
                // wraps the undo manager add to handle cached data
                editor.undoManager.add = function (level, event) {
                    try {
                        originalUnderManagerAdd(level, event);
                    } catch (e) {
                        console.error(e);
                        // ignore tinymce internal errors
                        return;
                    }
                    if (onUndoManagerAdd) {
                        onUndoManagerAdd(level, event);
                    }
                };

                editor.undoManager.reset();
                editor.custom.brailleDocument = doc;
                editor.custom.destroyed = false;
                Scroll.updateScroll(editor);

                let firstPage = editor.dom.select('editor-page')[0];
                if (!firstPage) {
                    firstPage = insertBlankPage(editor);
                }

                setCaretPosition(editor, firstPage, []);

                editor.on('SetContent', initializeDoc);
            }

            return () => {
                if (editor?.custom) editor.custom.destroyed = true;
                editor?.fire('documentDestroyed');
            };
        }, [editor]);

        function setup(editor) {
            let isShowingNonPrintableChars;

            // enter in full screen and disable deactivation
            editor.on('init', () => {
                editor.execCommand('mceFullScreen');
                editor.addShortcut(
                    'ctrl + shift + f',
                    // I18N
                    'Tela cheia',
                    () => {
                        editor.execCommand('mceFullScreen');
                        console.debug('Fullscreen deactivation disallowed.');
                    },
                );

                let observer = new MutationObserver((mutations) => {
                    mutations.forEach((mutation) => {
                        if (!editor.custom.isShowingTinyDialog) {
                            mutation.addedNodes.forEach((node) => {
                                // search/replace dialog showed
                                if (
                                    node?.getAttribute &&
                                    node.getAttribute('role') === 'dialog'
                                ) {
                                    console.debug('TinyMCE dialog detected.');
                                    editor.custom.isShowingTinyDialog = true;
                                    uncacheContent(editor);
                                    isShowingNonPrintableChars =
                                        editor.custom
                                            .isShowingNonPrintableChars;
                                    if (isShowingNonPrintableChars) {
                                        toggleNonPrintableChars(editor);
                                    }
                                }
                            });
                        }
                        if (editor.custom.isShowingTinyDialog) {
                            mutation.removedNodes.forEach((node) => {
                                // search/replace dialog hidden
                                if (
                                    node?.getAttribute &&
                                    node?.getAttribute('role') === 'dialog'
                                ) {
                                    console.debug('TinyMCE dialog removed.');
                                    editor.custom.isShowingTinyDialog = false;
                                    cacheContent(editor);
                                    if (isShowingNonPrintableChars) {
                                        toggleNonPrintableChars(editor);
                                    }
                                }
                            });
                        }
                    });
                });
                let config = {
                    childList: true,
                    subtree: true,
                };
                observer.observe(document, config);

                editor.custom = {
                    isShowingNonPrintableChars: false,
                    zoom: 1,
                };

                const coreModule = new CoreModule(
                    editor,
                    doc.readOnly,
                    userDocumentRoles,
                );
                editor.custom.coreModule = coreModule;
                coreModule.install();

                // this module must be initialized before the keyboard module due event capture order
                const excessLinesControlModule = new ExcessLinesControlModule(
                    editor,
                    coreModule.editorElements,
                );
                editor.custom.excessLinesControlModule =
                    excessLinesControlModule;
                excessLinesControlModule.install();

                const keyboardModule = new KeyboardModule(
                    editor,
                    doc.readOnly,
                    isAdmin,
                    isEvaluator,
                    isModerator,
                    userDocumentRoles,
                );
                editor.custom.keyboardModule = keyboardModule;
                keyboardModule.install();

                const mouseModule = new MouseModule(editor);
                editor.custom.mouseModule = mouseModule;
                mouseModule.install();

                const scrollModule = new ScrollModule(editor);
                editor.custom.scrollModule = scrollModule;
                scrollModule.install();

                editor.on('PastePreProcess', (event) => {
                    event.content = sanitizeHtml(editor, event.content);
                });
                editor.on('PastePostProcess', () => {
                    // not found a better way to do this yet
                    setTimeout(() => {
                        const currentPage = getCurrentPage(editor);
                        if (currentPage) {
                            editor.custom.coreModule.updatePage(currentPage);
                        }
                    }, 0);
                });
            });

            editor.on('scrollFinished', () => {
                storeNavigationPosition(editor);
            });

            InsertSymbolsAndSpecialCharacters.install(editor);

            installMenuModule(editor, {
                importPdfAction: onImportPdf,
                exitAction: exit,
                exportTxtAction: onExportBrailleFacil,
                exportPdfAction: onExportPdf,
                exportDocxAction: onExportDocx,
                saveDocumentAction: onEditDocument,
                removeDocumentAction: onRemoveDocument,
                renameAction: onRename,
                documentSettingsAction: onDocumentSettings,
                hyphenationSettingsAction: onHyphenationSettings,
                dictionaryAction: onDictionary,
                figuresAction: onAddFigure,
                coversAction: onAddCover,
                identificationAction: onIdentification,
                identificationSuppressInAllPagesAction:
                    onIdentificationSuppressInAllPagesAction,
                identificationExposeInAllPagesAction:
                    onIdentificationExposeInAllPagesAction,
                paginationAction: onPagination,
                userWithAccessAction: onUserWithAccess,
                changeDocumentStatusAction: onChangeDocumentStatus,
                forceUnlockDocumentAction: onForceUnlockDocument,
                retrieveImagesDescriptionsBackgroundAction:
                    onRetrieveImagesDescriptionsBackground,
                readOnly: doc.readOnly,
                isAdmin: isAdmin,
                isEvaluator: isEvaluator,
                isModerator: isModerator,
                isPrintShop: isPrintShop,
                isOwner: doc.ownedByUser,
                userDocumentRoles: userDocumentRoles,
                status: doc.status,
            });

            let tStoreNavigationPosition;
            /**
             * Used with NodeChange to prevent undesired scrolling behavior
             * @type {boolean}
             */
            let avoidScroll = false;
            editor.on('input', (e) => {
                if (onInput) onInput(e);
                clearTimeout(tStoreNavigationPosition);
                tStoreNavigationPosition = setTimeout(() => {
                    storeNavigationPosition(editor);
                    tStoreNavigationPosition = null;
                }, 1000);
                updateStatusCaretNavigation(editor);
                avoidScroll = true;
            });
            editor.on('Undo Redo', () => {
                avoidScroll = true;
            });

            editor.on('dragover', (e) => e.preventDefault());

            let tEditorElementChanged;
            editor.on('NodeChange', () => {
                if (avoidScroll) {
                    avoidScroll = false;
                    preventScroll(editor);
                }

                const selection = editor.selection.getNode();
                const closestEditorElement = getClosestEditorElement(selection);
                clearTimeout(tEditorElementChanged);
                tEditorElementChanged = setTimeout(() => {
                    if (closestEditorElement !== lastElementFocused.current) {
                        if (
                            closestEditorElement === null &&
                            lastElementFocused.current
                        ) {
                            /**
                             * @type {EditorElementFocusedEvent}
                             */
                            const editorElementFocusedEvent = {
                                element: lastElementFocused.current,
                            };

                            editor.fire(
                                'editorElementBlurred',
                                editorElementFocusedEvent,
                            );
                        } else if (closestEditorElement) {
                            /**
                             * @type {EditorElementFocusedEvent}
                             */
                            const editorElementFocusedEvent = {
                                element: closestEditorElement,
                            };
                            editor.fire(
                                'editorElementFocused',
                                editorElementFocusedEvent,
                            );
                        }
                        lastElementFocused.current = closestEditorElement;
                        updateStatusCaretNavigation(editor, true);
                    }
                }, 50);
            });

            editor.on('copy', (e) => {
                function getTinyMceData(editor) {
                    const selection = editor.selection.getSel();
                    if (selection.rangeCount) {
                        const container = document.createElement('div');
                        let i = 0,
                            len = selection.rangeCount;
                        for (; i < len; ++i) {
                            const range = selection.getRangeAt(i);
                            container.appendChild(range.cloneContents());
                        }
                        removeNonPrintableChars(container);
                        /**
                         * @type {HTMLElement[]}
                         */
                        const brailleViews = [
                            ...container.querySelectorAll(
                                'editor-braille-view',
                            ),
                        ];
                        for (let brailleView of brailleViews) {
                            brailleView.remove();
                        }
                        return {
                            html: replaceHtmlEdgeSpacesWithNbsp(
                                container.innerHTML,
                            ),
                            text: replaceHtmlEdgeSpacesWithNbsp(
                                container.innerHTML
                                    .replace(/<br\s*\/?>/gi, '\n')
                                    .replace(/(<([^>]+)>)/gi, ''),
                            ).replaceAll('&nbsp;', '\u00A0'),
                        };
                    }
                    return {
                        html: '',
                        text: '',
                    };
                }
                const tinyData = getTinyMceData(editor);
                // noinspection JSUnresolvedReference
                e.clipboardData.setData('text/html', tinyData.html);
                // noinspection JSUnresolvedReference
                e.clipboardData.setData(
                    'text/plain',
                    tinyData.text.replace(/[^\S\r\n]/g, ' '),
                );
                e.preventDefault();
            });
        }

        // noinspection JSValidateTypes,RequiredAttributes
        return (
            <Editor
                key={key}
                onInit={(evt, editor) => {
                    // noinspection JSCheckFunctionSignatures
                    setEditor(editor);
                }}
                tinymceScriptSrc={
                    '/assets/components/6.8.3/tinymce/tinymce.min.js'
                }
                init={{
                    a11y_ignore: 'ctrl+shift+f',
                    menubar: getTinyMceMenuBar(),
                    menu: getTinyMceMenu(),
                    content_css: `${process.env.PUBLIC_URL}/assets/css/TinyMce.css?version=${getVersion()}`,
                    extended_valid_elements:
                        'editor-page[data|data-*|style],' +
                        'editor-element[contenteditable|type|data|data-*|id|style],' +
                        '*[data-placeholder|data-src|style]',
                    custom_elements:
                        'editor-page,editor-element,document-break,cover-page',
                    forced_root_block: 'editor-page',
                    newline_behavior: 'invert',
                    image_dimensions: false,
                    browser_spellcheck: true,
                    plugins: ['fullscreen', 'searchreplace'],
                    toolbar: getTinyMceToolbar(doc.readOnly, userDocumentRoles),
                    toolbar_location: 'top',
                    fullscreen_native: false,
                    language_url: `/assets/js/tinymce-pt_BR.js?version=${getVersion()}`,
                    language: 'pt_BR',
                    smart_paste: false,
                    text_patterns: false,

                    /**
                     * @param editor {EditorCustom}
                     */
                    setup,
                }}
            />
        );
    },
);

RichEditor.displayName = 'RichEditor';

RichEditor.propTypes = {
    doc: PropTypes.object,
    onImportPdf: PropTypes.func,
    onEditDocument: PropTypes.func,
    onRemoveDocument: PropTypes.func,
    onRename: PropTypes.func,
    onHyphenationSettings: PropTypes.func,
    onDictionarySettings: PropTypes.func,
    onDocumentSettings: PropTypes.func,
    onInitialized: PropTypes.func,
    onInput: PropTypes.func,
    onExportBrailleFacil: PropTypes.func,
    onExportPdf: PropTypes.func,
    onExportDocx: PropTypes.func,
    onUserWithAccess: PropTypes.func,
    onChangeDocumentStatus: PropTypes.func,
    onForceUnlockDocument: PropTypes.func,
    onUndoManagerAdd: PropTypes.func,
    onRetrieveImagesDescriptionsBackground: PropTypes.func,
};
