import HtmlToBrailleFacil from './HtmlToBrailleFacil';
import * as FileSaver from 'file-saver';
import * as windows1252 from 'windows-1252';
import JSZip from 'jszip';

function htmlToNodes(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    let pages = [];
    const children = doc.body.children;
    for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child.tagName === 'EDITOR-PAGE') {
            child.setAttribute('num', (i + 1).toString());
            pages.push(child);
        }
    }
    return pages;
}

/**
 * @param html {string}
 * @param editorElements {EditorElements}
 * @param doc {BrailleDocument}
 * @return {string}
 */
function htmlToBrailleFacil(html, editorElements, doc) {
    const nodes = htmlToNodes(html);
    const htmlToBrailleFacil = new HtmlToBrailleFacil(
        nodes,
        editorElements,
        doc,
    );

    // source: https://sgm.codebit.com.br/manutencao/39980
    // I18N
    const header =
        `<*${doc.brailleCellColCount} caracteres x ${doc.brailleCellRowCount} linhas>\r\n` +
        `<${!doc.interPoint ? 'sem' : 'com'} interponto>\r\n` +
        `<${!doc.hyphenation ? 'sem' : 'com'} separação de sílabas>\r\n\r\n`;

    return header + htmlToBrailleFacil.convertToBraille();
}

/**
 * @param fileName {string}
 * @param splittedTxt {string[]}
 */
async function downloadFile(fileName, splittedTxt) {
    function stringToBlob(txt) {
        const encodedBuffer = windows1252
            .encode(txt, { mode: 'replacement' })
            .map((byte) => (byte === 0xfffd ? 239 : byte)); // replaces invalids characters to "ï"
        const bytes = new Uint8Array(encodedBuffer, 0, encodedBuffer.length);
        return new Blob([bytes], {
            type: 'text/plain;charset=iso-8859-1',
        });
    }

    if (splittedTxt.length > 1) {
        const zip = new JSZip();
        splittedTxt.forEach((txt, index) => {
            //I18N
            zip.file(`parte-${index + 1}.txt`, stringToBlob(txt));
        });
        const content = await zip.generateAsync({ type: 'blob' });
        FileSaver.saveAs(content, `${fileName}.zip`);
    } else {
        FileSaver.saveAs(stringToBlob(splittedTxt[0]), `${fileName}.txt`);
    }
}

/**
 * @param coverData {string}
 * @param totalPages {number}
 * @param page {number}
 * @param brailleMaxRows {number}
 * @param brailleMaxCols {number}
 * return {string}
 */
function convertCoverElementToEditorPage(
    coverData,
    totalPages,
    page,
    brailleMaxRows,
    brailleMaxCols,
) {
    const fragment = document.createDocumentFragment();
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = coverData;
    fragment.appendChild(tempDiv);

    let coverContent = fragment.querySelector(
        'div[data-type="cover"].content',
    ).innerHTML;
    let backCoverContent = fragment.querySelector(
        'div[data-type="back-cover"].content',
    ).innerHTML;
    let technicalSheetContent = fragment.querySelector(
        'div[data-type="technical-sheet"].content',
    )?.innerHTML;

    coverContent = replaceCustomDocumentVariables(
        coverContent,
        totalPages,
        page,
        brailleMaxRows,
        brailleMaxCols,
    );
    backCoverContent = replaceCustomDocumentVariables(
        backCoverContent,
        totalPages,
        page,
        brailleMaxRows,
        brailleMaxCols,
    );

    const coverReturnTxt = `
        <editor-page>${coverContent
            .split('<br>')
            .map(
                (el) =>
                    `<editor-element type="alignment-center">${el}</editor-element>`,
            )
            .join('<br>')}&lt;P&gt;<br></editor-page>
        <editor-page>${backCoverContent
            .split('<br>')
            .map(
                (el) =>
                    `<editor-element type="alignment-center">${el}</editor-element>`,
            )
            .join('<br>')}&lt;P&gt;<br></editor-page>
    `;

    let technicalSheetTxt = '';
    if (technicalSheetContent?.trim().length) {
        technicalSheetContent = replaceCustomDocumentVariables(
            technicalSheetContent,
            totalPages,
            page,
            brailleMaxRows,
            brailleMaxCols,
        );

        const splittedTechnicalSheetContent =
            technicalSheetContent.split('<br>');

        technicalSheetTxt += '<editor-page>&lt;P&gt;<br>';
        for (let i = 0; i < brailleMaxRows; i++) {
            technicalSheetTxt +=
                i < splittedTechnicalSheetContent.length
                    ? `<editor-element type="alignment-center">${splittedTechnicalSheetContent[i]}</editor-element><br>`
                    : i + 1 !== brailleMaxRows
                      ? '|<br>'
                      : replaceCustomDocumentVariables(
                            `h:::: Fim ${totalPages > 1 ? 'da' : 'do'} [NUM_PARTE_FICHA]<br>`,
                            totalPages,
                            page,
                            brailleMaxRows,
                            brailleMaxCols,
                        );
        }
        technicalSheetTxt += '</editor-page>';
    }

    return coverReturnTxt + technicalSheetTxt;
}

function replaceCustomDocumentVariables(text, totalParts, part, rows, cols) {
    const totalPageInWord = numberInWord(totalParts);
    const ordinalNumber = numberToOrdinal(part);

    // I18N
    return text
        .replaceAll(
            '[NUM_TOTAL_PARTES]',
            totalParts > 1 ? `${totalPageInWord} partes` : 'volume único',
        )
        .replaceAll(
            '[NUM_PARTE]',
            totalParts > 1
                ? `${ordinalNumber.charAt(0).toUpperCase() + ordinalNumber.slice(1)} Parte`
                : 'Volume único',
        )
        .replaceAll(
            '[NUM_PARTE_FICHA]',
            totalParts > 1
                ? `${ordinalNumber.charAt(0).toUpperCase() + ordinalNumber.slice(1)} Parte`
                : 'Volume',
        )
        .replaceAll('[ANO_CORRENTE]', `${new Date().getFullYear()}`)
        .replaceAll('[BRAILLE_LINHAS]', `${rows}`)
        .replaceAll('[BRAILLE_CARACTERES]', `${cols}`);
}

function numberToOrdinal(number) {
    // I18N
    const femaleOrdinal = [
        'primeira',
        'segunda',
        'terceira',
        'quarta',
        'quinta',
        'sexta',
        'sétima',
        'oitava',
        'nona',
        'décima',
        'décima primeira',
        'décima segunda',
        'décima terceira',
        'décima quarta',
        'décima quinta',
        'décima sexta',
        'décima sétima',
        'décima oitava',
        'décima nona',
        'vigésima',
        'vigésima primeira',
        'vigésima segunda',
        'vigésima terceira',
        'vigésima quarta',
        'vigésima quinta',
        'vigésima sexta',
        'vigésima sétima',
        'vigésima oitava',
        'vigésima nona',
        'trigésima',
        'trigésima primeira',
        'trigésima segunda',
        'trigésima terceira',
        'trigésima quarta',
        'trigésima quinta',
        'trigésima sexta',
        'trigésima sétima',
        'trigésima oitava',
        'trigésima nona',
        'quadragésima',
        'quadragésima primeira',
        'quadragésima segunda',
        'quadragésima terceira',
        'quadragésima quarta',
        'quadragésima quinta',
        'quadragésima sexta',
        'quadragésima sétima',
        'quadragésima oitava',
        'quadragésima nona',
        'quinquagésima',
        'quinquagésima primeira',
        'quinquagésima segunda',
        'quinquagésima terceira',
        'quinquagésima quarta',
        'quinquagésima quinta',
        'quinquagésima sexta',
        'quinquagésima sétima',
        'quinquagésima oitava',
        'quinquagésima nona',
        'sexagésima',
        'sexagésima primeira',
        'sexagésima segunda',
        'sexagésima terceira',
        'sexagésima quarta',
        'sexagésima quinta',
        'sexagésima sexta',
        'sexagésima sétima',
        'sexagésima oitava',
        'sexagésima nona',
        'septuagésima',
        'septuagésima primeira',
        'septuagésima segunda',
        'septuagésima terceira',
        'septuagésima quarta',
        'septuagésima quinta',
        'septuagésima sexta',
        'septuagésima sétima',
        'septuagésima oitava',
        'septuagésima nona',
        'octogésima',
        'octogésima primeira',
        'octogésima segunda',
        'octogésima terceira',
        'octogésima quarta',
        'octogésima quinta',
        'octogésima sexta',
        'octogésima sétima',
        'octogésima oitava',
        'octogésima nona',
        'nonagésima',
        'nonagésima primeira',
        'nonagésima segunda',
        'nonagésima terceira',
        'nonagésima quarta',
        'nonagésima quinta',
        'nonagésima sexta',
        'nonagésima sétima',
        'nonagésima oitava',
        'nonagésima nona',
    ];

    if (number >= 1 && number <= femaleOrdinal.length) {
        return femaleOrdinal[number - 1];
    } else {
        return `${number}ª`;
    }
}

function numberInWord(number) {
    // I18N
    const units = [
        'zero',
        'uma',
        'duas',
        'três',
        'quatro',
        'cinco',
        'seis',
        'sete',
        'oito',
        'nove',
        'dez',
        'onze',
        'doze',
        'treze',
        'quatorze',
        'quinze',
        'dezesseis',
        'dezessete',
        'dezoito',
        'dezenove',
    ];

    // I18N
    const dozens = [
        '',
        '',
        'vinte',
        'trinta',
        'quarenta',
        'cinquenta',
        'sessenta',
        'setenta',
        'oitenta',
        'noventa',
    ];

    if (number < 20) {
        return units[number];
    } else if (number < 100) {
        const ten = Math.floor(number / 10);
        const unit = number % 10;
        // I18N
        return unit === 0 ? dozens[ten] : `${dozens[ten]} e ${units[unit]}`;
    } else {
        // I18N
        return `${number}`;
    }
}

export {
    htmlToNodes,
    htmlToBrailleFacil,
    downloadFile,
    convertCoverElementToEditorPage,
};
